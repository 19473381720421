import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input, FormGroup, Button } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'
import CsvDownloadButton from 'react-json-to-csv'


const MeetingList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleEditMeetingingData,
  handleCancelMeetingingData,
  handleChangeStatus,
  statusValue,
  handleClear,
  handleUpdateStatus,
  csvData,
  isCsvLoaded,
  handleCallDataForCsv,
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          gap: '2px',
        }}
        key={row.user_id}
      >
        {['super_admin', 'admin'].includes(userRole()) && (
          <>
            {row.status !== 'Cancelled' && <>
              <i
                className="ri-edit-2-fill text-warning h4 cursor-pointer mr-2"
                onClick={() => handleEditMeetingingData(row.id, row.booking_int_id, row.backend_status, row)}
              />
              <i
                className="dripicons-cross text-danger h4 cursor-pointer mr-2"
                onClick={() => handleCancelMeetingingData(row.id, row.booking_int_id)}
              />
            </>}
            <i
              className="ri-message-3-fill text-primary h4 cursor-pointer mr-2"
              onClick={() => handleUpdateStatus(row)}
            />
          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className='align-items-center'>
              <Col xs={4}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Meeting List</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={3} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height:'35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
              <Col xs={2}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={statusValue}
                    onChange={handleChangeStatus}
                    className="form-control"
                  >
                    <option hidden value=''>Select</option>
                    <option value='open'>open</option>
                    <option value='cancelled'>Cancelled</option>
                    <option value='completed'>Completed</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={1}>
                <Button onClick={handleClear}>Clear</Button>
              </Col>
              {isCsvLoaded ? (['super_admin', 'admin'].includes(userRole()) && <Col xs={1}>
                <CsvDownloadButton data={csvData} /></Col>) : 
                <Col xs={1}><Button onClick={handleCallDataForCsv}><i className='ri-download-2-line' ></i></Button></Col>}
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default MeetingList
