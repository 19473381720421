import { useEffect, } from 'react'
import { useNavigate } from 'react-router'
import { isAuthorized } from 'Utils/localStorage'

const DecisionComponent = () => {
  const navigate = useNavigate()

  useEffect(() => {
    isAuthorized() ? navigate('/dashboard/users/all') : navigate('/auth/login')
  }, [])

  return null
}

export default DecisionComponent