export const refundListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Created On',
    dataField: 'created_on',
  },
  {
    text: 'Service Type',
    dataField: 'service_type',
  },
  {
    text: 'Parent Name.',
    dataField: 'parent_fullname',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Dog Name',
    dataField: 'dogs',
  },
  {
    text: 'Payment Id',
    dataField: 'payment_intent_id',
  },
  {
    text: 'Payment Amount',
    dataField: 'payment_amount',
  },
  {
    text: 'Refund Amount',
    dataField: 'amount',
  },
  {
    text: 'Status',
    dataField: 'status',
  },
]

export const paymentListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Service Type',
    dataField: 'service_type',
  },
  {
    text: 'Parent Name.',
    dataField: 'parent_fullname',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Dog Name',
    dataField: 'dogs',
  },
  {
    text: 'Date',
    dataField: 'date',
  },
  {
    text: 'Amount',
    dataField: 'amount',
  },
  {
    text: 'Status',
    dataField: 'status',
  },
]

export const allRefundListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Created On',
    dataField: 'created_on',
  },
  {
    text: 'Service Type',
    dataField: 'service_type',
  },
  {
    text: 'Parent Name.',
    dataField: 'parent_fullname',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Dog Name',
    dataField: 'dogs',
  },
  {
    text: 'Payment Id',
    dataField: 'payment_intent_id',
  },
  {
    text: 'Refund Id',
    dataField: 'refund_gateway_id',
  },
  {
    text: 'Payment Amount',
    dataField: 'payment_amount',
  },
  {
    text: 'Refund Amount',
    dataField: 'amount',
  },
  {
    text: 'Status',
    dataField: 'status',
  },
]

export const allPendingInvoiceListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Created On',
    dataField: 'created_on',
  },
  {
    text: 'Service Type',
    dataField: 'service_type',
  },
  {
    text: 'Parent Name.',
    dataField: 'parent_fullname',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Amount',
    dataField: 'amount',
  },
  {
    text: 'Status',
    dataField: 'status',
  },
]