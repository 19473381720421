import handleSitterUsersKycData from '../Users/Views/RegisteredUsers/index'
import { getRegisteredSitterListreq } from './Users.Apis'

export const SitterUsersListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'App Date',
    dataField: 'application_date',
  },
  {
    text: 'DOB',
    dataField: 'date_of_birth',
  },
  {
    text: 'Age',
    dataField: 'age',
  },
]


export const SitterUsersKycListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Submitted On',
    dataField: 'application_date',
  },
  {
    text: 'DOB',
    dataField: 'date_of_birth',
  },
  {
    text: 'Age',
    dataField: 'age',
  },
  {
    text: 'First Login',
    dataField: 'is_first_calender_booked',
  }
]

export const SitterUsersApprovedListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Approved On',
    dataField: 'application_date' ,
    sort: true
  },
  {
    text: 'Last Action',
    dataField: 'last_event_action_on',
    sort: true
  },
  {
    text: 'Age',
    dataField: 'age',
  },
  {
    text: 'First Login',
    dataField: 'is_first_calender_booked',
  },
  {
    text: 'Reminder Count',
    dataField: 'first_calendar_reminder_type',
  },
]

export const SitterUsersBookingListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Approved On',
    dataField: 'application_date',
  },
  {
    text: 'DOB',
    dataField: 'date_of_birth',
  },
  {
    text: 'Age',
    dataField: 'age',
  },
  {
    text: 'First Login',
    dataField: 'is_first_calender_booked',
  },
  {
    text: 'Reminder Count',
    dataField: 'pending_app2_reminder_type',
  },

]


export const SitterUsersRejectedListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Rejected On',
    dataField: 'application_date',
  },
  {
    text: 'Rejection Stage',
    dataField: 'rejection_stage',
  },
  {
    text: 'Reason of Rejection',
    dataField: 'reason_of_rejection',
  },
]