import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input, Button } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'
import Select from 'react-select'
import { languageOptions } from 'Components/EditSitterprofile/helper'
import CsvDownloadButton from 'react-json-to-csv'

const ParentUserList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleEditParentData,
  handleViewParentData,
  handleDeleteParentData,
  handleDogAction,
  page,
  sizePerPage,
  totalSize,
  searchData,
  handleSearch,
  hearFromSource,
  handleHearFromSource,
  languages,
  handleLanguages,
  handleFilter,
  dataCount,
  isLoading,
  csvData,
  isCsvLoaded,
  handleCallDataForCsv,
  handleUpdateStatus
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '2px' }} key={row.user_id}>
        {['super_admin', 'admin'].includes(userRole()) && <i
          className="ri-eye-line text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewParentData(row.user_id)}
        />}
        {['super_admin'].includes(userRole()) && <div style={{ textAlign: 'center' }} key={row.user_id}>
          <i
            className="ri-edit-2-fill text-warning h4 cursor-pointer mr-2"
            onClick={() => handleEditParentData(row.user_id)}
          />
        </div>}
        {['super_admin'].includes(userRole()) && <div style={{ textAlign: 'center' }} key={row.user_id}>
          <i
            className="ri-baidu-fill text-success h4 cursor-pointer mr-2"
            onClick={() => handleDogAction(row.user_id)}
          />
        </div>}
        {['super_admin', 'admin'].includes(userRole()) && <i
          className="ri-delete-bin-3-fill text-danger text-info h4 cursor-pointer mr-2"
          onClick={() => handleDeleteParentData(row.user_id)}
        />}
        {['super_admin', 'admin'].includes(userRole()) && <i
          className="ri-message-3-fill text-primary h4 cursor-pointer mr-2"
          onClick={() => handleUpdateStatus(row)}
        />}
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Registered Parents</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={12}>
                <Row className='align-items-end'>
                  <Col xs={2} className="">
                    <Label htmlFor="searchSitter">Search</Label>
                    <Input
                      style={{ height: '35px' }}
                      name="searchSitter"
                      value={searchData}
                      onChange={handleSearch}
                      type="text"
                      className="form-control"
                      id="searchSitter"
                    />
                  </Col>
                  <Col xs={2}>
                    <Label htmlFor='hear_from_source'>Source</Label>
                    <Input
                      value={hearFromSource}
                      onChange={handleHearFromSource}
                      type='select'
                    >
                      <option value=''>Select</option>
                      <option value='Google Search'>Google Search</option>
                      <option value='Facebook group'>Facebook group</option>
                      <option value='Through a friend'>Through a friend</option>
                      <option value='Booked With DogStays before'>Booked With DogStays before</option>
                      <option value='Facebook Ad'>Facebook Ad</option>
                      <option value='Google Ad'>Google Ad</option>
                      <option value='Other'>Other</option>
                    </Input>
                  </Col>
                  <Col xs={4}>
                    <Label htmlFor='preferred_languages'>
                      languages
                    </Label>
                    <Select
                      className='select-font'
                      options={languageOptions}
                      // value={languages}
                      isMulti
                      onChange={handleLanguages}
                      name='preferred_languages'
                      placeholder='Select languages'
                    />
                  </Col>
                  <Col xs={1} className="text-center">
                    <Label htmlFor="searchSitter">Count</Label>
                    <p className='text-success'>{dataCount}</p>
                  </Col>
                  <Col xs={1}>
                    <Button type="button" onClick={handleFilter} color="primary" className="waves-effect">Seacrh</Button>
                  </Col>
                  {isCsvLoaded ? (['super_admin', 'admin'].includes(userRole()) && <Col xs={1}>
                    <CsvDownloadButton data={csvData} /></Col>) : 
                    <Col xs={1}><Button onClick={handleCallDataForCsv}><i className='ri-download-2-line' ></i></Button></Col>}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ParentUserList
