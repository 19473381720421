export const formatMultiSelectData = (data) => {
  const formatedData = data?.map(({ value }) => value)
  return formatedData
}

export const modifyArray = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] })
  }
  return updatedArray
}

export const formatArrayForCountry = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr, label: arr, value: arr })
  }
  return updatedArray
}

export const locationTypeOptions = [
  { value: 'parent_location', label: 'parent_location' },
  { value: 'sitter_location', label: 'sitter_location' },
  { value: 'other_address', label: 'other_address' }
]