import { useEffect, useMemo, useState } from 'react'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { formatArrayForCountry, formatMultiSelectData, locationTypeOptions, modifyArray } from './helper'
import { getParentAddressById } from 'Views/Dashboard/Views/Parents/Api'

const EditBookingLocationComp = ({ locationsDetails, parentUserId, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const countryOptions = useMemo(() => countryList().getData(), [])
  const [allAddressList, setAllAddressList] = useState([])

  const [locationsDetail, setLocationsDetail] = useState({
    location_type: locationsDetails.location_type,
    comfortable_with_sitter_dog: locationsDetails.comfortable_with_sitter_dog,
    comfortable_with_sitter_dog_text: locationsDetails.comfortable_with_sitter_dog_text,
    comfortable_with_sitter_cat: locationsDetails.comfortable_with_sitter_cat,
    comfortable_with_sitter_cat_text: locationsDetails.comfortable_with_sitter_cat_text,
    comfortable_with_sitter_children: locationsDetails.comfortable_with_sitter_children,
    comfortable_with_sitter_children_text: locationsDetails.comfortable_with_sitter_children_text,
    parent_address: locationsDetails.parent_address || '',
    manual_address: {
      street_name: locationsDetails?.manual_address?.street_name,
      city: locationsDetails?.manual_address?.city,
      country: locationsDetails?.manual_address?.country,
      postal_code: locationsDetails?.manual_address?.postal_code,
      id: locationsDetails?.manual_address?.id
    }
  })

  useEffect(() => {
    getParentAddressById(parentUserId).then((res) => {setAllAddressList(res.data.data)})
  },[])


  useEffect(() => {
    updatedData(locationsDetail)
  }, [locationsDetail])

  return (
    <>
      <Col lg='6' >
        <FormGroup>
          <Label>Select Location Type</Label>
          <Select className='select-font' isMulti placeholder='Select' options={locationTypeOptions}
            value={modifyArray(locationsDetail.location_type)}
            onChange={(value) => setLocationsDetail({ ...locationsDetail, location_type: formatMultiSelectData(value) })}
          />
        </FormGroup>
      </Col>
      {locationsDetail.location_type.includes('sitter_location') &&
        <Row>
          <h4 className='text-center'>Sitter Location</h4>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is it OK if the sitter`s home has a cat?</Label>
              <Input value={locationsDetail.comfortable_with_sitter_cat} type="select" className="form-control"
                onChange={(e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_cat: e.target.value }) }}>
                <option selected disabled value=""> Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="other">Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Additional Info(cat)</Label>
              <textarea value={locationsDetail.comfortable_with_sitter_cat_text} type="text" className="form-control"
                onChange={
                  (e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_cat_text: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is it OK if the sitter`s home has a children?</Label>
              <Input value={locationsDetail.comfortable_with_sitter_children} type="select" className="form-control"
                onChange={
                  (e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_children: e.target.value }) }}>
                <option selected disabled value=""> Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="other">Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Additional Info(children)</Label>
              <textarea type="text" className="form-control" value={locationsDetail.comfortable_with_sitter_children_text}
                onChange={
                  (e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_children_text: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is it OK if the sitter`s home has a dog?</Label>
              <Input value={locationsDetail.comfortable_with_sitter_dog} type="select" className="form-control"
                onChange={(e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_dog: e.target.value }) }}>
                <option selected disabled value=""> Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="other">Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Additional Info(dog)</Label>
              <textarea value={locationsDetail.comfortable_with_sitter_dog_text} type="text" className="form-control"
                onChange={
                  (e) => { setLocationsDetail({ ...locationsDetail, comfortable_with_sitter_dog_text: e.target.value }) }} />
            </FormGroup>
          </Col>
        </Row>}

      {locationsDetail.location_type.includes('parent_location') &&
        <Row>
          <h4 className='text-center'>Parent Location</h4>
          <Col lg='6'>
            <FormGroup>
              <Label>Parent Address</Label>
              <Input value={locationsDetail.parent_address.id} type="select" className="form-control"
                onChange={(e) => { setLocationsDetail({ ...locationsDetail, parent_address: e.target.value }) }}>
                <option selected disabled value=" "> Select</option>
                {allAddressList.length > 0 && allAddressList.map((res) => {
                  return <option value={Number(res.id)} key={res.id}>
                    {res.street_name + ' ' + res.city + ' ' + res.country + ' ' + res.postal_code}</option>
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>}

      {locationsDetail.location_type.includes('other_address') &&
        <Row>
          <h4 className='text-center'>Other Location(New)</h4>
          <Col lg='6'>
            <FormGroup>
              <Label>Street Name</Label>
              <Input value={locationsDetail.manual_address.street_name} type="text" className="form-control"
                onChange={(e) => {
                  setLocationsDetail({
                    ...locationsDetail, manual_address:
                      { ...locationsDetail.manual_address, street_name: e.target.value }
                  })
                }} />
            </FormGroup>
          </Col>
          <Col lg='6'>
            <FormGroup>
              <Label>City</Label>
              <Input value={locationsDetail.manual_address.city} type="text" className="form-control"
                onChange={(e) => {
                  setLocationsDetail({
                    ...locationsDetail, manual_address:
                      { ...locationsDetail.manual_address, city: e.target.value }
                  })
                }} />
            </FormGroup>
          </Col>
          <Col lg='6'>
            <FormGroup>
              <Label>Country</Label>
              <Select className='select-font' options={countryOptions}
                value={(locationsDetail.manual_address.country)}
                onChange={(value) => {
                  setLocationsDetail({
                    ...locationsDetail, manual_address:
                      { ...locationsDetail.manual_address, country: (value) }
                  })
                }}
              />
            </FormGroup>
          </Col>
          <Col lg='6'>
            <FormGroup>
              <Label>Postal Code</Label>
              <Input value={locationsDetail.manual_address.postal_code} type="text" className="form-control"
                onChange={(e) => {
                  setLocationsDetail({
                    ...locationsDetail, manual_address:
                      { ...locationsDetail.manual_address, postal_code: e.target.value }
                  })
                }} />
            </FormGroup>
          </Col>
        </Row>}
    </>
  )
}

export default EditBookingLocationComp