import PendingSitterList from 'Components/SitterWaitingList'
import SitterStatus from 'Components/statusNote'
import ViewSitterData from 'Components/ViewSitterData'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SitterUsersListTableHeading } from '../../Constants'
import { approveApplication1, getSitterPendingListreq, getUserDataByIdReq, updateSitterInforeq } from '../../Users.Apis'

const AllPendingUsers = () => {
  const [sitter, setSitter] = useState([])
  const [usersList, setUsersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [reasonforRejection, setReasonforRejection] = useState('')
  const [userId, setUserId] = useState('')
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [showAcceptReqAlert, setShowAcceptReqAlert] = useState(false)
  const [showAcceptReqConfirm, setShowAcceptReqConfirm] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [isSitterStatusModalOpen, setIsSitterStatusModalOpen] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setSitter(data.data)
      setUpadtedStatus(data.data.admin_notes)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  
  
  const toggleCreateModel = () => {
    setIsCreateModalOpen(true)
  }

  const handleCloseModal = () => {
    setSitter([])
    setIsCreateModalOpen(false)
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleSitterUsersData({ search: val })
  }


  const handleViewSitterData = (UserId) => {
    getDataByUserId(UserId)
    toggleCreateModel()
  }

  const handleSitterUsersData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getSitterPendingListreq(queryParams)
      setUsersList(data.data)
      setTotalUserCount(data.data.length)
      // setPaginationValue({
      //   currentPage: queryParams.page,
      //   totalSize: data.count,
      // })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleSitterUsersData({ search: '' })
  }, [])

  const approveApplication = async(UserId) => {
    try {
      await approveApplication1( { 'is_approved' : true } , UserId )
    } catch (error) {
      console.log(error)
    }
  }

  const rejectApplication = async(UserId) => {
    try {
      await approveApplication1( { 'is_approved': false, 'reason_of_rejection': reasonforRejection } , UserId )
    } catch (error) {
      console.log(error)
    }
  }

  const handleAcceptReq = () => {
    approveApplication(userId)
    setTimeout(() => {
      handleSitterUsersData({ search: '' })
    },1000)
    setShowAcceptReqAlert(false)
    setShowAcceptReqConfirm(true)
  }

  const alertAccepetReq = (UserId) => {
    setUserId(UserId)
    setShowAcceptReqAlert(true)
  }

  const handleRejectReq = () => {
    rejectApplication(userId)
    setTimeout(() => {
      handleSitterUsersData({ search: '' })
    },1000)
    setIsOpen(false)
  }

  const alertRejectReq = (UserId) => {
    setUserId(UserId)
    setIsOpen(true)
  }

  const handleReasonOfRejection = (e) => {
    setReasonforRejection(e.target.value)
  }

  const handleViewSitterStatus = async(UserId) => {
    await getDataByUserId(UserId)
    setUserId(UserId)
    setIsSitterStatusModalOpen(true)
  }

  const handleChangeStatus = (e) => {
    setUpadtedStatus(e.target.value)
  }

  const handleUpdateStatusReq = async() => {
    try {
      await updateSitterInforeq({
        admin_notes: upadtedStatus
      }, userId)
      setIsSitterStatusModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <PendingSitterList
        tableData={usersList}
        tableHeading={SitterUsersListTableHeading}
        totalCount={totalUserCount}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewSitterData={handleViewSitterData}
        handleViewSitterStatus={handleViewSitterStatus}
        handleAcceptReq={alertAccepetReq}
        handleRejectReq={alertRejectReq}
        searchData={searchData}
        handleSearch={handleSearch}
      />

      <ViewSitterData
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        sitterData= {sitter}
        handleClose={handleCloseModal}
      />

      <SitterStatus
        isOpen={isSitterStatusModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={handleChangeStatus}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => setIsSitterStatusModalOpen(false)}
      />

      {showAcceptReqAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Accept it!"
          reverseButtons={true}
          onConfirm={handleAcceptReq}
          onCancel={() => setShowAcceptReqAlert(false)}
        ></SweetAlert>
      ) : null}

      {showAcceptReqConfirm ? (
        <SweetAlert
          success
          title="Accepted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowAcceptReqAlert(false)
            setShowAcceptReqConfirm(false)
          }}
        >
          Application has been Accepted
        </SweetAlert>
      ) : null}
        


      {isOpen && (
        <div>
          <Modal size="lg" isOpen={isOpen} backdrop="static">
            <ModalHeader>Delete Action</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="reason_of_reject">Reason For Rejection</Label>
                <Input
                  name="reason_of_reject"
                  // value={reasonOfRejection}
                  onChange={handleReasonOfRejection}
                  type="textarea"
                  className="form-control"
                  id="reason_of_reject"
                />
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={handleRejectReq}
                    >
                      Reject
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsOpen(false)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default AllPendingUsers
