import { useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { updateSitterInforeq } from 'Views/Dashboard/Views/Users/Users.Apis'

const ViewStatus = (
  {
    isOpen,
    popupTitle,
    value,
    handleClose
  }
) => {
  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <ModalHeader>{popupTitle}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="admin_notes">Status</Label>
          <Input
            name="admin_notes"
            value={value}
            type="textarea"
            className="form-control"
            id="admin_notes"
            disabled
          />
        </FormGroup>
        <ModalFooter>
          <div className="row w-100">
            <div className="col-6">
              <Button
                className="btn btn-danger w-75"
                type="button"
                onClick={handleClose}
              >
                close
              </Button>
            </div>
          </div>
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

export default ViewStatus