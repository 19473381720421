import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import ViewBookingLocationComp from './viewLocationComp'

const ViewHomeVisitData = ({ homeVistData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  return (
    <>
      <h5 className='my-2' style={{ color: '#e8833a', fontWeight: 500 }}>SERVICE LOCATION&apos;S </h5>
      <Row>
        <p className='m-0'>Number of home care visits:
          <span className='font-bold'> {homeVistData.number_of_home_care_visits}</span></p>
        <p className='m-0'>What would you like the dog sitter to do for your dog during the visit:
          <span className='font-bold'>{homeVistData.home_care_activities}</span></p>
      </Row>
      <ViewBookingLocationComp bookingData={homeVistData} />
    </>
  )
}

export default ViewHomeVisitData