import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import ViewDaycareData from './ViewType/daycare'
import ViewHomeVisitData from './ViewType/homevisit'
import ViewOvernightData from './ViewType/overnight'
import { useEffect, useState } from 'react'

const ViewBookingDetailsData = ({ isOpen, bookingData = [], handleClose }) => {
  const dataArray = bookingData

  const [dogsNames, setDogsNames] = useState('')
  const { dogs } = bookingData

  useEffect(() => {
    const arr = []
    dogs && dogs.map((res) => { arr.push(res.name) })
    setDogsNames(arr.join(', '))
  }, [bookingData])


  const style = { borderRight: '1px solid #ced4da' }


  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>View Booking Details</ModalHeader>
      <ModalBody>
        <Row className='p-2 py-4' style={{ background: '#c3cfd9' }}>
          <Col lg='6'>
            <h5 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>DOG PARENTS DETAILS </h5>
            <p className='m-0'>Name: <span className='font-bold'>{dataArray.parent}</span></p>
            <p className='m-0'>Id: <span className='font-bold'>{dataArray.parent_id}</span></p>
            <p className='m-0'>Email: <span className='font-bold'>{dataArray.parent_details?.email}</span></p>
            <p className='m-0'>Phone Number: <span className='font-bold'>{dataArray.parent_details?.mobile}</span></p>
            <p className='m-0'>Gender: <span className='font-bold'>{dataArray.parent_details?.gender}</span></p>
            <p className='m-0'>Language:
              <span className='font-bold'>{dataArray.parent_details?.preferred_languages}</span></p>
            <p className='m-0'>Whatsapp No:
              <span className='font-bold'>{dataArray.parent_details?.whatsapp_number}</span></p>
          </Col>

          <Col lg='6'>
            <h5 className='mb-2' style={{ color: '#e8833a', fontWeight: 500 }}>DOG&apos;S DETAILS </h5>
            {dogs && dogs.map((item, index) => {
              return (
                <div className='mb-3' key={index}>
                  <p className='mb-1'>Dog #{index + 1}</p>
                  <p className='m-0'>Name: <span className='font-bold'>{item.name}</span></p>
                  <p className='m-0'>Gender: <span className='font-bold'>{item.gender}</span></p>
                  <p className='m-0'>Breed: <span className='font-bold'>{item.breed}</span></p>
                  <p className='m-0'>DOB: <span className='font-bold'>{item.date_of_birth}</span></p>
                  <p className='m-0'>Age: <span className='font-bold'>{item.age}</span></p>
                  <p className='m-0'>Size Category: <span className='font-bold'>{item.size_category}</span></p>
                </div>
              )
            })
            }
          </Col>
        </Row>

        <Row>
          <h5 className='my-2' style={{ color: '#e8833a', fontWeight: 500 }}>BOOKING REQUEST DETAILS </h5>
          <p className='m-0'>Service Type: <span className='font-bold'>{dataArray.service_type_display}</span></p>
          <p className='m-0'>Which dog is this request is for: <span className='font-bold'>{dogsNames}</span></p>
          <p className='m-0'>List of dog sitters you have booked with: 
            <span className='font-bold'>{dataArray.preferred_sitters}</span></p>
          <p className='m-0'>Sitter Names: <span className='font-bold'>{dataArray.preferred_sitters_text}</span></p>
          <p className='m-0'>Would you be comfortable with a video call instead of a meeting: 
            <span className='font-bold'> {dataArray.available_for_video_call}</span></p>
          <p className='m-0'>Comfortable splitting your booking across two different dog sitters: 
            <span className='font-bold'> {dataArray.comfortable_splitting_booking}</span></p>
          <p className='m-0'>Is it OK if the dog sitter is caring for another dog on some of your dates: 
            <span className='font-bold'> {dataArray.sitter_caring_for_another_dog}</span></p>

        </Row>
        {dataArray.service_type == 'Daycare' && <ViewDaycareData daycareData={dataArray} />}
        {dataArray.service_type == 'Home Visit' && <ViewHomeVisitData homeVistData={dataArray} />}
        {dataArray.service_type == 'Overnight Boarding' && <ViewOvernightData overnightData={dataArray} />}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewBookingDetailsData
