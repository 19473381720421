import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import InteractionPlugin from '@fullcalendar/interaction'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Input, Col } from 'reactstrap'

const Calender = ({
  handleDateClick,
  eventsData = [],
  handleClose,
  isOpen,
  selectable,
  select,
  removeEvent,
  editable,
  timeZone,
  eventClick,
  sitterName,
  inActiveEndDate,
  statusEndDate,
  submitInActiveStatus,
  userActiveStatusText,
  clearInActiveStatus,
  handleCreateDaycareModal
}) => {
  return (
    <>
      <Modal size="xl" isOpen={isOpen} backdrop="static">
        <ModalHeader>View the Sitter Calender - {sitterName}</ModalHeader>
        <ModalBody>
          <div className="row justify-content-center">
            <FullCalendar
              plugins={[dayGridPlugin, InteractionPlugin]}
              initialView="dayGridMonth"
              dateClick={handleDateClick}
              selectable={selectable}
              editable={editable}
              select={select}
              events={eventsData}
              eventRemove={removeEvent}
              timeZone={timeZone}
              eventClick={eventClick}
              displayEventTime={false}
              // displayEventEnd={true}
              // aspectRatio='1.5'
              // slotDuration={{ hours: 12 }}
              // slotLabelInterval={{ hours: 24 }}
              // slotLabelFormat={[{ month: 'long', week: 'short' }, { weekday: 'narrow', day: 'numeric' }]}
              // timeFormat='h:mma'
              // forceEventDuration={true}
              // defaultAllDay={false}
            />
            <div className='inactive-module mt-4 bg-light border p-3'>
              <div className='row justify-content-between'>
                <Col xs={10}>
                  <h4>STATUS - {userActiveStatusText ? userActiveStatusText : '-'}</h4>
                </Col>
                <Col xs={2} className='text-right'>
                  <Button className='mx-1 w-100' onClick={handleCreateDaycareModal} color='success'>Create Daycare</Button>
                </Col>
              </div>
              <div className='row align-items-center'>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor="end_time">End Date</Label>
                    <Input
                      name="end_time"
                      value={statusEndDate}
                      onChange={inActiveEndDate}
                      type="date"
                      className="form-control"
                      id="end_time"
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </FormGroup>
                </Col>
                <Col xs={2}>
                  <Button className='mx-1' onClick={submitInActiveStatus} color='primary'>Submit</Button>
                  <Button className='mx-1' onClick={clearInActiveStatus} color='warning'>Clear</Button>
                </Col>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Calender
