import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'reactstrap'
import { Routes, Route, Navigate } from 'react-router-dom'

import { Loader } from 'Components'
import DashboardLayout from 'Layout/DashboardLayout'
import { fetchUserData } from 'Views/Authentication/Auth.actions'

import { DashboardRoutes } from './Constants/routes'

const Dashboard = () => {
  const dispatch = useDispatch()

  const { auth } = useSelector(state => state)

  useEffect(() => {
    dispatch(fetchUserData())
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <>
        {auth.isLoggedIn ? (
          <DashboardLayout>
            {!auth.isLoading ? (
              <div className="page-content">
                <Container fluid>
                  <Routes>
                    {DashboardRoutes.map(({ path, Component }) => (
                      <Route path={path} element={<Component />} key={path} />
                    ))}
                    <Route
                      path="*"
                      element={<Navigate to="/dashboard/users/all" replace />}
                      key="*"
                    />
                  </Routes>
                </Container>
              </div>
            ) : (
              <Loader />
            )}
          </DashboardLayout>
        ) : (
          <Navigate to="/auth/login" replace />
        )}
      </>
    </>
  )
}

export default Dashboard
