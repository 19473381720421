import UsersPaymentList from '../Views/AllPayments'
import UsersAllRefundPaymentList from '../Views/AllRefunds'
import MyConfig from '../../Config'
import PendingInvoices from '../Views/Invoice'
import UsersRefundPaymentList from '../Views/Refunds'

export const paymentRoutes = [
  {
    Component: UsersPaymentList,
    path: '/all',
    exact: true,
    routePath: '/dashboard/payment/all',
    name: 'All Payments',
  },
  {
    Component: UsersAllRefundPaymentList,
    path: '/pending',
    exact: true,
    routePath: '/dashboard/payment/pending',
    name: 'All Refunds',
  },
  {
    Component: UsersRefundPaymentList,
    path: '/refund',
    exact: true,
    routePath: '/dashboard/payment/refund',
    name: `Refunds Pending (${JSON.parse(localStorage.getItem('dataCount'))?.pending_refund_count || '0'})`,
  },
  {
    Component: PendingInvoices,
    path: '/invoices',
    exact: true,
    routePath: '/dashboard/payment/invoices',
    name: `Invoice's Pending (${JSON.parse(localStorage.getItem('dataCount'))?.pending_invoice_count || '0'})`,
  }
]