import React, { useState } from 'react'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton,
  AccordionItemPanel } from 'react-accessible-accordion'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import 'react-accessible-accordion/dist/fancy-example.css'
import Select from 'react-select'
import { age_of_dog_option, elevator_options, floor_options, languages_option, maybe_option, outdoor_spaces_options,
  schedule_options, size_of_dog_option } from 'Views/Dashboard/Views/Filters/Views/AllFilters/selectOptions'
import { getBookingSearchResultsViaFilters } from '../../../Api'
import { formatMultiSelectData } from 'Components/EditBookingDetailsData/EditType/helper'


const BookingFilters = ({ filterDataBasedOnAction }) => {
  const [filterAction, setFilterAction] = useState()
  const [multiFilterActions, setMultiFilterActions] = useState({
    size_of_dog: '',
    age_of_dog: '',
    preferred_languages: '',
    daily_schedule: '',
    floor: '',
    home_has_elevator: '',
    comfortable_with_non_toilet_trained: '',
    comfortable_with_two_dogs: '',
    comfortable_with_separation_anxiety: '',
    outdoor_spaces: '',
    has_driving_license: '',
    has_car: ''
  })

  const handleInputChange = (e) => {
    setFilterAction({
      ...filterAction,
      [e.target.name]: e.target.value,
    })
  }
  
  const handleApplyFilters = (e) => {
    getBookingSearchResultsViaFilters({
      have_your_own_dog: filterAction?.have_your_own_dog || '',
      have_your_own_cat: filterAction?.have_your_own_cat || '',
      have_children: filterAction?.have_children || '',
      is_inactive: filterAction?.is_inactive || '',
      size_of_dog: multiFilterActions.size_of_dog && formatMultiSelectData(multiFilterActions.size_of_dog),
      age_of_dog: multiFilterActions.age_of_dog && formatMultiSelectData(multiFilterActions.age_of_dog),
      preferred_languages: multiFilterActions.preferred_languages &&
          formatMultiSelectData(multiFilterActions.preferred_languages),
      daily_schedule: multiFilterActions.daily_schedule && formatMultiSelectData(multiFilterActions.daily_schedule),
      floor: multiFilterActions.floor && formatMultiSelectData(multiFilterActions.floor),
      home_has_elevator: multiFilterActions.home_has_elevator && formatMultiSelectData(multiFilterActions.home_has_elevator),
      comfortable_with_non_toilet_trained: multiFilterActions.comfortable_with_non_toilet_trained &&
          formatMultiSelectData(multiFilterActions.comfortable_with_non_toilet_trained),
      comfortable_with_two_dogs: multiFilterActions.comfortable_with_two_dogs &&
          formatMultiSelectData(multiFilterActions.comfortable_with_two_dogs),
      comfortable_with_separation_anxiety: multiFilterActions.comfortable_with_separation_anxiety &&
          formatMultiSelectData(multiFilterActions.comfortable_with_separation_anxiety),
      outdoor_spaces: multiFilterActions.outdoor_spaces && formatMultiSelectData(multiFilterActions.outdoor_spaces),
      sensitivity: filterAction?.sensitivity || '',
      has_driving_license: filterAction?.has_driving_license || '',
      has_car: filterAction?.has_car || ''
    }).then((res) => {
      res?.data?.response_code == 80 && filterDataBasedOnAction(res?.data?.data?.results)
    })
  }
  return (
    <Accordion
      className="accordion border-0"
      preExpanded={[1, 2]}
      allowMultipleExpanded
    >
      <AccordionItem className="accordion-item border-0" uuid={0}>
        <AccordionItemHeading className=" py-0 backgroud_light">
          <AccordionItemButton
            style={{
              display: 'flex',
              alignItems: 'center',
              background: 'white',
            }}
          >
            <h4 className="accordion-header">Advance Filters</h4>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Col xs={12}>
            <Form>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="have_your_own_dog" className='w-50'>Has Dog</Label>
                      <p className='text-primary w-50 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }}
                        onClick={() => setFilterAction({
                          ...filterAction, have_your_own_dog: ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="have_your_own_dog"
                      placeholder="Enter "
                      value={filterAction?.have_your_own_dog}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="have_your_own_dog"
                    >
                      <option hidden value="">Select</option>
                      <option value="True">Yes</option>
                      <option value="False">No</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="size_of_dog">Size Of Dog</Label>
                    <Select
                      options={size_of_dog_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        size_of_dog: e
                      })}}
                      value={multiFilterActions?.size_of_dog}
                      name="size_of_dog"
                      placeholder="Select Sizes"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="age_of_dog">Age of Dog</Label>
                    <Select
                      options={age_of_dog_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        age_of_dog: e
                      })}}
                      value={multiFilterActions?.age_of_dog}
                      name="age_of_dog"
                      placeholder="Select Ages"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="have_your_own_cat" className='w-50'>Has Cat</Label>
                      <p className='text-primary w-50 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }}
                        onClick={() => setFilterAction({
                          ...filterAction, have_your_own_cat: ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="have_your_own_cat"
                      value={filterAction?.have_your_own_cat}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="have_your_own_cat"
                    >
                      <option hidden value=''>Select</option>
                      <option value="True">Yes</option>
                      <option value="False">No</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="preferred_languages">Languages</Label>
                    <Select
                      options={languages_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        preferred_languages: e
                      })}}
                      value={multiFilterActions?.preferred_languages}
                      name="preferred_languages"
                      placeholder="Select Languages"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="daily_schedule">Schedule</Label>
                    <Select
                      options={schedule_options}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        daily_schedule: e
                      })}}
                      value={multiFilterActions?.daily_schedule}
                      name="daily_schedule"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="floor">Floor</Label>
                    <Select
                      options={floor_options}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        floor: e
                      })}}
                      value={multiFilterActions?.floor}
                      name="floor"
                      placeholder="Select Floor"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="home_has_elevator">Elevator</Label>
                    <Select
                      options={elevator_options}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        home_has_elevator: e
                      })}}
                      value={multiFilterActions?.home_has_elevator}
                      name="home_has_elevator"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="have_children" className='w-75'>Have Children</Label>
                      <p className='text-primary w-25 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }}
                        onClick={() => setFilterAction({
                          ...filterAction, have_children: ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="have_children"
                      value={filterAction?.have_children}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="have_children"
                    >
                      <option hidden value=''>Select</option>
                      <option value="True">Yes</option>
                      <option value="False">No</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="comfortable_with_non_toilet_trained">Toilet training</Label>
                    <Select
                      options={maybe_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        comfortable_with_non_toilet_trained: e
                      })}}
                      value={multiFilterActions?.comfortable_with_non_toilet_trained}
                      name="comfortable_with_non_toilet_trained"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="comfortable_with_two_dogs">Care for 2 dogs</Label>
                    <Select
                      options={maybe_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        comfortable_with_two_dogs: e
                      })}}
                      value={multiFilterActions?.comfortable_with_two_dogs}
                      name="comfortable_with_two_dogs"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="comfortable_with_separation_anxiety">Dogs with issues</Label>
                    <Select
                      options={maybe_option}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        comfortable_with_separation_anxiety: e
                      })}}
                      value={multiFilterActions?.comfortable_with_separation_anxiety}
                      name="comfortable_with_separation_anxiety"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <Label htmlFor="outdoor_spaces">outdoor spaces</Label>
                    <Select
                      options={outdoor_spaces_options}
                      isMulti
                      onChange={(e) => {setMultiFilterActions({
                        ...multiFilterActions,
                        outdoor_spaces: e
                      })}}
                      value={multiFilterActions?.outdoor_spaces}
                      name="outdoor_spaces"
                      placeholder="Select"
                    />
                  </FormGroup>
                </Col>

                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="is_inactive" className='w-75'>Sitter is inactive Today</Label>
                      <p className='text-primary w-25 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }}
                        onClick={() => setFilterAction({
                          ...filterAction, is_inactive: ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="is_inactive"
                      value={filterAction?.is_inactive}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="is_inactive"
                    >
                      <option hidden value=''>Select</option>
                      <option value="1">YES</option>
                      <option value="0">NO</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="sensitivity" className='w-75'>Sensitivity</Label>
                      <p className='text-primary w-25 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }}
                        onClick={() => setFilterAction({
                          ...filterAction, Sensitivity: ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="sensitivity"
                      value={filterAction?.Sensitivity}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="sensitivity"
                    >
                      <option hidden value="">Select</option>
                      <option value="4">4 Hours</option>
                      <option value="8">8 Hours</option>
                      <option value="12">12 Hours</option>
                      <option value="24">1 Day</option>
                      <option value="48">2 Days</option>
                      <option value="72">3 Days</option>
                      <option value="96">4 Days</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="has_driving_license" className='w-75'>Has Driving License</Label>
                      <p className='text-primary w-25 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }} 
                        onClick={() => setFilterAction({
                          ...filterAction, has_driving_license : ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="has_driving_license"
                      value={filterAction?.has_driving_license}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="has_driving_license"
                    >
                      <option hidden value=''>Select</option>
                      <option value="True">Yes</option>
                      <option value="False">No</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs={3}>
                  <FormGroup>
                    <Row className='justify-content-between'>
                      <Label htmlFor="has_car" className='w-75'>Has Car</Label>
                      <p className='text-primary w-25 m-0 text-end'
                        style={{ textTransform: 'underline', cursor: 'pointer' }} 
                        onClick={() => setFilterAction({
                          ...filterAction, has_car : ''
                        })}>Clear</p>
                    </Row>
                    <Input
                      name="has_car"
                      value={filterAction?.has_car}
                      onChange={handleInputChange}
                      type="select"
                      className="form-control"
                      id="has_car"
                    >
                      <option hidden value=''>Select</option>
                      <option value="True">Yes</option>
                      <option value="False">No</option>
                    </Input>
                  </FormGroup>
                </Col>

              </Row>
              <Row className="justify-content-center">
                <Col
                  xs={5}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Button
                    className="btn btn-success w-75"
                    type="button"
                    onClick={handleApplyFilters}
                  >
                    Apply Filter
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default BookingFilters