import PartialApprovedSitterList from 'Components/PartialApprovedUserList'
import { useEffect, useState } from 'react'
import { SitterUsersBookingListTableHeading, SitterUsersKycListTableHeading } from '../../Constants'
import { approveApplication1, deleteRejectedSitterListreq, getSitterPartialPendingKycListreq,
  getUserDataByIdReq, updateSitterInforeq } from '../../Users.Apis'
import SweetAlert from 'react-bootstrap-sweetalert'
import ViewSitterData from 'Components/ViewSitterData'
import SitterStatus from 'Components/statusNote'
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'


const PartialApprovedUsersList = () => {
  const [usersList, setUsersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [showAcceptReqAlert, setShowAcceptReqAlert] = useState(false)
  const [showAcceptReqConfirm, setShowAcceptReqConfirm] = useState(false)
  const [isSitterStatusModalOpen, setIsSitterStatusModalOpen] = useState(false)
  const [isReasonOfRejectionModal, setIsReasonOfRejectionModal] = useState(false)
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [reasonforRejection, setReasonforRejection] = useState('')
  const [sitterDatas, setSitterDatas] = useState({
    isOpen: false,
    sitterData: [],
    handleClose: false
  })
  const { isOpen, sitterData, handleClose } = sitterDatas
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const [searchData, setSearchData] = useState('')

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handlePartialApprovedUsersData({ search: val })
  }

  const handlePartialApprovedUsersData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getSitterPartialPendingKycListreq(queryParams)
      setUsersList(data.data)
      setTotalUserCount(data.data.length)
      // setPaginationValue({
      //   currentPage: queryParams.page,
      //   totalSize: data.count,
      // })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handlePartialApprovedUsersData({ search: '' })
  }, [])

  const handleDeleteSitterData = (UserId) => {
    setUserId(UserId)
    setIsReasonOfRejectionModal(true)
  }

  const handleViewSitterData = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setSitterDatas({
        ...sitterDatas,
        sitterData: data.data,
        isOpen: true
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // const deleteDataByUserId = async() => {
  //   try {
  //     const { data } = await deleteRejectedSitterListreq(userId)
  //     setShowAcceptReqAlert(false)
  //     setShowAcceptReqConfirm(true)
  //     handlePartialApprovedUsersData()
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleViewSitterStatus = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setUserId(UserId)
      setUpadtedStatus(data.data.admin_notes)
      setIsSitterStatusModalOpen(true)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

  }

  const handleChangeStatus = (e) => {
    setUpadtedStatus(e.target.value)
  }

  const handleUpdateStatusReq = async() => {
    try {
      await updateSitterInforeq({
        admin_notes: upadtedStatus
      }, userId)
      setIsSitterStatusModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleReasonOfRejection = (e) => {
    setReasonforRejection(e.target.value)
  }

  const rejectApplication = async(UserId) => {
    try {
      await approveApplication1( { 'is_approved': false, 'reason_of_rejection': reasonforRejection } , UserId )
    } catch (error) {
      console.log(error)
    }
  }

  const handleRejectReq = () => {
    rejectApplication(userId)
    setTimeout(() => {
      handlePartialApprovedUsersData({ search: '' })
    },1000)
    setIsReasonOfRejectionModal(false)
  }
  return (
    <>
      <PartialApprovedSitterList
        tableData={usersList}
        totalCount={totalUserCount}
        tableHeading={SitterUsersBookingListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewSitterData={handleViewSitterData}
        handleViewSitterStatus={handleViewSitterStatus}
        handleDeleteSitterData={handleDeleteSitterData}
        searchData={searchData}
        handleSearch={handleSearch}
      />

      <ViewSitterData 
        isOpen={isOpen}
        sitterData={sitterData}
        handleClose={() => {
          setSitterDatas({
            ...sitterDatas,
            isOpen: false
          })
        }}
      />

      <SitterStatus
        isOpen={isSitterStatusModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={handleChangeStatus}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => setIsSitterStatusModalOpen(false)}
      />

      {/* {showAcceptReqAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteDataByUserId}
          onCancel={() => setShowAcceptReqAlert(false)}
        ></SweetAlert>
      ) : null}

      {showAcceptReqConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowAcceptReqAlert(false)
            setShowAcceptReqConfirm(false)
          }}
        >
          Application has been Accepted
        </SweetAlert>
      ) : null} */}

      {isReasonOfRejectionModal && (
        <div>
          <Modal size="lg" isOpen={isReasonOfRejectionModal} backdrop="static">
            <ModalHeader>Delete Action</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="reason_of_reject">Reason For Rejection</Label>
                <Input
                  name="reason_of_reject"
                  // value={reasonOfRejection}
                  onChange={handleReasonOfRejection}
                  type="textarea"
                  className="form-control"
                  id="reason_of_reject"
                />
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={handleRejectReq}
                    >
                      Reject
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsReasonOfRejectionModal(false)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default PartialApprovedUsersList
