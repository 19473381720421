import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Grid,
  FormControl,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { weekDaysArr } from 'Utils/helper'
import dayjs from 'dayjs'
import DatePicker from 'react-multi-date-picker'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import { updateBookingDates } from 'Views/Dashboard/Views/Parents/Api'

const Daycare = ({ bookingData, setStep }) => {
  const [modifiedDaycareData, setModifiedDaycareData] = useState([])
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0)

  const ref = useRef()

  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map(
      (value) => value.year + '-' + value.month + '-' + value.day
    )
    const newDateObject = convertDate.filter(
      (date) => !modifiedDaycareData.some((item) => item.date === date)
    )
    const filteredArr = modifiedDaycareData.filter((item) =>
      convertDate.includes(item.date)
    )
    const modifiedJson = newDateObject.map((item) => {
      const data = {
        date: item,
        startTime: '',
        endTime: '',
        totalHours: '',
        day_type: '', // Full or half day
      }
      mappedData.push(data)
    })
    setDayCareSelectDates(convertDate)
    // setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ])
    mappedData.length > 0
      ? setModifiedDaycareData([...modifiedDaycareData, ...mappedData])
      : setModifiedDaycareData(filteredArr)
  }

  function calculateTimeDifference(startTime, endTime) {
    if (startTime && endTime) {
      const [hours1, minutes1] = startTime.split(':').map(Number)
      const [hours2, minutes2] = endTime.split(':').map(Number)
      const totalMinutes1 = hours1 * 60 + minutes1
      const totalMinutes2 = hours2 * 60 + minutes2
      let timeDifference = totalMinutes2 - totalMinutes1
      const hoursDifference = Math.floor(timeDifference / 60)
      const minutesDifference = timeDifference % 60
      return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
    } else return
  }

  const calculateHoursToMins = (hours) => {
    if (hours) {
      const [hour, minutes] = hours.split(':').map(Number)
      const totalMinutes = hour * 60 + minutes
      return totalMinutes
    }
  }

  const handleUpdateDates = () => {
    updateBookingDates({
      'daycare_dates': modifiedDaycareData
    }, bookingData.booking_id).then((res) => {
      res.data.response_code == 80 && setStep(1)
    })
  }

  const dayType = []

  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if (item.day_type !== '') {
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map((day) => {
        if (day === 'Half Day') {
          return 0.5
        } else if (day === 'Full Day') {
          return 1
        } else if (day === 'One Half') {
          return 1.5
        }
        return day
      })
      let totalDaysSum = 0
      convertedArray.forEach((num) => {
        totalDaysSum += num
      })
      setDaycareDaysTime(totalDaysSum)
      return
    })
  }, [modifiedDaycareData])
  

  console.log(modifiedDaycareData, 'modifiedDaycareData')
  return (
    <>
      <Row className='mt-4'>
        <Col xs={12}>
          <Col xs={12}>
            <FormGroup fullWidth variant="standard">
              <Typography shrink="true">
                Select Dates{' '}
                <span style={{ color: 'red', fontSize: '20px' }}>*</span>
              </Typography>
              <DatePicker
                ref={ref}
                className="date-picker-layout"
                multiple
                onChange={handledDayCareMultipleDates}
                value={dayCareSelectDates}
                dateFormat="DD-MM-YYYY"
              >
                <Button
                  className="py-1"
                  style={{ position: 'relative', bottom: '5px' }}
                  color="primary"
                  onClick={() => ref.current.closeCalendar()}
                >
                  close
                </Button>
              </DatePicker>
            </FormGroup>
          </Col>
          <Col xs={12}>
            {modifiedDaycareData.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    gap: '14px',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                  key={index}
                >
                  <span
                    onClick={() => {
                      const calenderItems = [...dayCareSelectDates]
                      const modifyItems = [...modifiedDaycareData]
                      calenderItems.splice(index, 1)
                      modifyItems.splice(index, 1)
                      setModifiedDaycareData(modifyItems)
                      setDayCareSelectDates(calenderItems)
                    }}
                  >
                    <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"></i>
                  </span>
                  <Col xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Typography shrink="true">Selected Dates</Typography>
                      <TextField
                        type="text"
                        size="small"
                        disabled
                        value={item.date}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Typography shrink="true">Start Time</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          sx={{ marginTop: '5px' }}
                          defaultValue={dayjs('2022-04-17T18:30')}
                          value={item.startTime}
                          ampm={false}
                          closeOnSelect={true}
                          onChange={(time) => {
                            const updatedData = {
                              ...item,
                              startTime: time.format('HH:mm'),
                              totalHours: calculateTimeDifference(
                                time.format('HH:mm'),
                                item.endTime
                              ),
                            }
                            setModifiedDaycareData((prevData) => {
                              const newData = [...prevData]
                              newData[index] = updatedData
                              return newData
                            })
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Col>
                  <Col xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Typography shrink="true">End Time</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          sx={{ marginTop: '5px' }}
                          defaultValue={dayjs()}
                          value={item.endTime}
                          ampm={false}
                          onChange={(time) => {
                            const updatedData = {
                              ...item,
                              endTime: time.format('HH:mm'),
                              totalHours: calculateTimeDifference(
                                item.startTime,
                                time.format('HH:mm')
                              ),
                              day_type:
                                calculateHoursToMins(
                                  calculateTimeDifference(
                                    item.startTime,
                                    time.format('HH:mm')
                                  )
                                ) == 0
                                  ? ''
                                  : calculateHoursToMins(
                                    calculateTimeDifference(
                                      item.startTime,
                                      time.format('HH:mm')
                                    )
                                  ) > 601
                                    ? 'One Half'
                                    : calculateHoursToMins(
                                      calculateTimeDifference(
                                        item.startTime,
                                        time.format('HH:mm')
                                      )
                                    ) > 301
                                      ? 'Full Day'
                                      : 'Half Day',
                            }
                            setModifiedDaycareData((prevData) => {
                              const newData = [...prevData]
                              newData[index] = updatedData
                              return newData
                            })
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Col>
                  <Col xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Typography shrink="true">No of Hours</Typography>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Hours"
                        disabled
                        value={item.totalHours}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Typography shrink="true">No of Days</Typography>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Days"
                        disabled
                        value={item.day_type}
                      />
                    </FormControl>
                  </Col>
                </div>
              )
            })}
            {modifiedDaycareData.length > 0 && (
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '15px',
                  marginRight: '10px',
                }}
              >
                <Typography
                  component="h4"
                  color="text.secondary"
                  fontWeight="bold"
                  fontFamily="brandon"
                >
                  {`Total days: ${daycareDataDaysTime}`}
                </Typography>
              </Grid>
            )}
          </Col>
        </Col>
        <Row className='justify-content-center'>
          <Button type="button" color="primary w-25"
            onClick={handleUpdateDates} className="waves-effect">Continue</Button>
        </Row>
      </Row>
    </>
  )
}

export default Daycare
