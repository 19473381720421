import { useEffect, useState } from 'react'
import { getUsersPendingMeetingsListData } from '../../Api'
import { pendingMeetingListTableHeading } from '../../tableCol'
import ChangeReqMeetingList from 'Components/ChangeMeetingReqList'
import EditMeetingData from 'Components/EditMeetingDetails'
import ViewStatus from 'Components/viewStatusNote'

const UsersPendingMeetingList = () => {
  const [usersList, setUsersList] = useState([])
  const [searchData, setSearchData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const [notesData, setNotesData] = useState('')
  const [bookingId, setBookingId] = useState({ id: '', bookingIntId: '', bookingStatus: '' })
  const [isEditMode, setIsEditMode] = useState(false)
  const [meetingData, setMeetingData] = useState([])
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleGetPendingMeetingData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUsersPendingMeetingsListData(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetPendingMeetingData({ page: 1, search: '' })
  }, [])



  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetPendingMeetingData({ page, search: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetPendingMeetingData({ page:1 , search: val })
  }

  const handleEditMeetingingData = (id, bookingIntId, status, data) => {
    setMeetingData(data)
    setBookingId({ ...bookingId, id: id, bookingIntId: bookingIntId, bookingStatus: status })
    setIsEditMode(true)
  }

  const handleViewNotesStatus = (data) => {
    setNotesData(data.notes)
    setShowNote(true)
  }

  return(
    <>
      <ChangeReqMeetingList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={pendingMeetingListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
        handleEditMeetingingData={handleEditMeetingingData}
        handleViewNotesStatus={handleViewNotesStatus}
      />

      {isEditMode && <EditMeetingData
        data={meetingData}
        isOpen={isEditMode}
        handleClose={() => setIsEditMode(false)}
        bookingIntId={bookingId.bookingIntId}
        id={bookingId.id}
        bookingStatus={bookingId.bookingStatus}
        reRenderPage={() => handleGetPendingMeetingData({ page: 1, search: '' })}
      />}

      {showNote && <ViewStatus
        popupTitle='Last Status'
        isOpen={showNote}
        value={notesData}
        handleClose={() => {
          setNotesData('')
          setShowNote(false)}}
      />}

    </>
  )

}

export default UsersPendingMeetingList