import RejectedSitterList from 'Components/RejectedUserList'
import ViewSitterData from 'Components/ViewSitterData'
import ViewSitterProfile from 'Components/ViewSitterProfile'
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { SitterUsersRejectedListTableHeading } from '../../Constants'
import { deleteRejectedSitterListreq, getRejectedSitterListreq, getUserDataByIdReq } from '../../Users.Apis'

const rejectedUsersList = () => {
  const [usersList, setUsersList] = useState([])
  const [sitter, setSitter] = useState([])
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [showAcceptReqAlert, setShowAcceptReqAlert] = useState(false)
  const [showAcceptReqConfirm, setShowAcceptReqConfirm] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleSitterUsersKycData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getRejectedSitterListreq(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleSitterUsersKycData({ page: 1, search: '' })
  }, [])


  const handleViewSitterData = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setSitter(data.data)
      setIsCreateModalOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteSitterData = (UserId) => {
    setUserId(UserId)
    setShowAcceptReqAlert(true)
  }

  const deleteDataByUserId = async() => {
    try {
      const { data } = await deleteRejectedSitterListreq(userId)
      setShowAcceptReqAlert(false)
      setShowAcceptReqConfirm(true)
      handleSitterUsersKycData()
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleCreateModel = () => {
    setIsCreateModalOpen(true)
  }

  const handleCloseModal = () => {
    setSitter([])
    setIsCreateModalOpen(false)
  }

  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleSitterUsersKycData({ page, search: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleSitterUsersKycData({ page:1 , search: val })
  }

  return(
    <>
      <RejectedSitterList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={SitterUsersRejectedListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewSitterData={handleViewSitterData}
        handleDeleteSitterData={handleDeleteSitterData}
        searchData={searchData}
        handleSearch={handleSearch}
      />

      {sitter.rejection_stage === 'Application-1' ? 
        <ViewSitterData
          isOpen={isCreateModalOpen}
          toggleModal={toggleCreateModel}
          sitterData= {sitter}
          handleClose={handleCloseModal}
        /> :
        <ViewSitterProfile
          isOpen={isCreateModalOpen}
          toggleModal={toggleCreateModel}
          sitterData={sitter}
          handleClose={handleCloseModal}
        /> 
      }

      
      {showAcceptReqAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteDataByUserId}
          onCancel={() => setShowAcceptReqAlert(false)}
        ></SweetAlert>
      ) : null}

      {showAcceptReqConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowAcceptReqAlert(false)
            setShowAcceptReqConfirm(false)
          }}
        >
          Application has been Accepted
        </SweetAlert>
      ) : null}
    </>
  )

}

export default rejectedUsersList