import { createDog, updateDogById } from 'Views/Dashboard/Views/Parents/Api'
import axios from 'axios'
import { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const CreateDogProfile = ({ isOpen, handleClose, userId }) => {

  const style = { borderRight: '1px solid #ced4da' }
  const [editedImagesArr, setEditedImagesArr] = useState([])

  const [dogFormData, setDogFormData] = useState({
    name: null, breed: null, gender: null, photos: editedImagesArr || [], dob: null, is_neutered: null, age_category: null,
    size_category: null, is_friendly_with_other_dogs: null, is_friendly_with_other_dogs_text: null,
    is_comfortable_around_cat: null, is_comfortable_around_cat_text: null, chew_or_damage_things: null,
    chew_or_damage_things_text: null, can_left_alone: null,
    can_left_how_long: null, can_left_how_long_text: null, reaction_toward_children: null, reaction_toward_stranger: null,
    is_toilet_trained: null, is_toilet_trained_text: null, is_aggressive: null, is_aggressive_text: null, is_vaccinated: null,
    is_vaccinated_text: null, additional_info: null,
  })
  const {
    name, breed, gender, photos, dob, is_neutered, age_category, size_category, is_friendly_with_other_dogs,
    is_friendly_with_other_dogs_text, is_comfortable_around_cat, is_comfortable_around_cat_text, chew_or_damage_things,
    chew_or_damage_things_text, can_left_alone, can_left_how_long, can_left_how_long_text, reaction_toward_children,
    reaction_toward_stranger, is_toilet_trained, is_toilet_trained_text, is_aggressive, is_aggressive_text, is_vaccinated,
    is_vaccinated_text, additional_info } = dogFormData


  const createDogInfo = () => {
    createDog(setDogFormData(dogFormData, userId)).then((res) => {
      if(res.data.response_code === 80){
        handleClose()
      }
      if(res.data.response_code === 90){
        console.log(res.data.developer_message)
      }
    })
  }

  const DeleteSelectFile = (id) => {
    if (window.confirm('Are you sure you want to delete this Image?')) {
      const result = editedImagesArr.filter((item, index) => index !== id)
      setEditedImagesArr(result)
    }
  }

  const onChangeFileInput = async(e) => {
    const imageFormData = new FormData()
    if(editedImagesArr.length < 3){
      for (let i = 0; i < 3 - editedImagesArr.length; i++) {
        const uploadFile = e.target.files[i]
        imageFormData.append('image', uploadFile)
      }
      await axios.post('https://api.dogstays.lu/basic/multi/upload/image', imageFormData)
        .then((res) => {
          setEditedImagesArr([...editedImagesArr,...res.data.data.url])
        })
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Create Dog`s Profile</ModalHeader>
      <ModalBody>
        <Row>
          <h4 className='mb-2'>Basic Information :- </h4>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Dog Name</Label>
              <Input value={name} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, name: e.target.value })} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Dog Breed</Label>
              <Input value={breed} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, breed: e.target.value })} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Gender</Label>
              <Input value={gender} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, gender: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Date of birth</Label>
              <Input value={dob} type="date" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, dob: e.target.value })} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Neutered</Label>
              <Input value={is_neutered} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_neutered: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Soon">Not yet but will be soon</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>What age category is your dog?</Label>
              <Input value={age_category} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, age_category: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="young_puppy">Very Young Puppy (less than 1 year old)</option>
                <option value="puppy">Puppy (less than 2 years old)</option>
                <option value="adult_dog">Adult dog (over 2 years old)</option>
                <option value="senior_dog">Senior dog (above 7 years old)</option>
                <option value="all_age">All ages welcome</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>What size category is your dog?</Label>
              <Input value={size_category} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, size_category: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="small">Small (less than 14 kilos)</option>
                <option value="medium">Medium (between 14 and 25 kilos)</option>
                <option value="large">Large (more than 25 kilos)</option>
                <option value="all_size">All sizes OK</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h4 className='mb-2'>Additional Information :- </h4>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is your dog friendly with other dogs?</Label>
              <Input value={is_friendly_with_other_dogs} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_friendly_with_other_dogs: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">It Depends</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (dogs)</Label>
              <textarea value={is_friendly_with_other_dogs_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_friendly_with_other_dogs_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is your dog comfortable around cats?</Label>
              <Input value={is_comfortable_around_cat} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_comfortable_around_cat: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">Not sure</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (cats)</Label>
              <textarea value={is_comfortable_around_cat_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_comfortable_around_cat_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Does your dog chew or damage things?</Label>
              <Input value={chew_or_damage_things} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, chew_or_damage_things: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">others</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (crew)</Label>
              <textarea value={chew_or_damage_things_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, chew_or_damage_things_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Can your dog be left alone at home for some time?</Label>
              <Input value={can_left_alone} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, can_left_alone: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">others</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>For how long?</Label>
              <Input value={can_left_how_long} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, can_left_how_long: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="1-2 hours">1-2 hours</option>
                <option value="2-4 hours">2-4 hours</option>
                <option value="4-6 hours"> 4-6 hours</option>
                <option value="6-8 hours"> 6-8 hours</option>
                <option value="As long as needed">As long as needed</option>
                <option value="Other"> other</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>How does your dog react to children?</Label>
              <textarea value={reaction_toward_children} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, reaction_toward_children: e.target.value })} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>How does your dog react to strangers?</Label>
              <textarea value={reaction_toward_stranger} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, reaction_toward_stranger: e.target.value })} />
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is your dog completely toilet trained?</Label>
              <Input value={is_toilet_trained} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_toilet_trained: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">others</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (toilet)</Label>
              <textarea value={is_toilet_trained_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_toilet_trained_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is your dog aggressive in any situation?</Label>
              <Input value={is_aggressive} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_aggressive: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">others</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (aggressive)</Label>
              <textarea value={is_aggressive_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_aggressive_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is your dog fully vaccinated?</Label>
              <Input value={is_vaccinated} type="select" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_vaccinated: e.target.value })}
              >
                <option selected disabled value=''>Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                <option value="other">others</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Please share additional details here: (vaccinated)</Label>
              <textarea value={is_vaccinated_text} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, is_vaccinated_text: e.target.value })} />
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Is there anything else you would like us or the dog sitter to know?</Label>
              <textarea value={additional_info} type="text" className="form-control"
                onChange={(e) => setDogFormData({ ...dogFormData, additional_info: e.target.value })} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h4 className='mb-2'>Upload new photos :- </h4>
          <FormGroup>
            <Label>Add Images</Label>
            <Input type='file' accept='.jpg,.jpeg,.png' multiple onChange={onChangeFileInput} />
          </FormGroup>
          <div style={{ display: 'flex', gap: '10px' }}>
            {
              editedImagesArr?.map((item, index) => {
                return (
                  <div key={index} style={{ position: 'relative', border: '1px solid #454545' }}>
                    <p style={{
                      position: 'absolute', top: 0, right: 0, width: 'auto',
                      padding: '5px', background: 'grey', color: 'white', cursor: 'pointer'
                    }} onClick={() => DeleteSelectFile(index)}>Remove</p>
                    <img width='250' src={item} />
                  </div>

                )
              })
            }
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={createDogInfo}
          color="primary"
          className="waves-effect"
        >
          Create
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateDogProfile
