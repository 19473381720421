import axios from 'axios'

const subDomain = 'https://api.dogstays.lu/'

export const getUsersMeetingsListData = ({ page, search, status, page_size }) => {
  return axios.get(`${subDomain}administrator/meeting/list?page=${page}&search=${search}&status=${status}` + 
  `&page_size=${page_size}`) 
}

export const getUsersPendingMeetingsListData = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/meeting/pending_update/list?page=${page}&search=${search}`) 
}

export const updateMeetingData = (payload, id) => {
  return axios.put(`${subDomain}administrator/meeting/update/${id}`, payload)
}

export const updatePendingMeetingData = (payload, id) => {
  return axios.put(`${subDomain}administrator/meeting/pending_update/update/${id}`, payload)
}

export const cancelMeetingData = (id) => {
  return axios.put(`${subDomain}administrator/meeting/cancel/${id}`)
}