import MyConfig from '.'


export const configRoutes = [
  {
    Component: MyConfig,
    path: '/config',
    exact: true,
    routePath: '/dashboard/config',
    name: 'All Config',
  }
]