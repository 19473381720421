import setDefaultHeaders from 'Utils/setDefaultHeaders'
import { clearLocal } from 'Utils/localStorage'

// export function* handleLoginUserEffect({ payload, navigator }) {
//   try {
//     const { data } = yield call(loginUserAPI, payload)
   
//     if(!data.token || !data.user_id) {
//       console.log('Dispatch Error')
//       return 
//     }
//     setLocal('token', data.token)
//     setDefaultHeaders('authorization', `Bearer ${data.token}`)

//     yield put(loginUserSuccess())
//     if (navigator) navigator('/')
//   } catch (error) {
//     // yield put(loginUserError(error))
//     // yield put(setAlert({ message: error.response.data.message, type: 'ERROR' }))
//   }
// }

export function handleLogoutUserEffect() {
  setDefaultHeaders('authorization')
  clearLocal()
}

// export function* handleFetchUserDataEffect() {
//   try {
//     const { data } = yield call(fetchUserDataAPI)
//     console.log(data)
//     // setLocal('role', data.role[0]);
//     yield put(loginUserSuccess(data))
//   } catch (error) {
//     yield put(logoutUserAction())
//     // yield put(setAlert({ message: error.response.data.message, type: 'ERROR' }))
//   }
// }
