import { capturePayment } from 'Views/Dashboard/Views/Payments/Api'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const RecordPaymentModal = ({ isOpen, handleClose, data, reRenderTable }) => {

  const handleConfirm = () => {
    capturePayment(data?.id).then((res) => {
      res.data.response_code == 80 && handleClose(), reRenderTable()
    })
  }

  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Record Customer Payment</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={4}>
            <Label className='mb-0'>Booking Req Id</Label><br/>
            <Label>{data?.booking_id}</Label>
          </Col>
          <Col xs={4}>
            <Label className='mb-0'>Parent Name</Label><br/>
            <Label>{data?.parent_full_name}</Label>
          </Col>
          <Col xs={4}>
            <Label className='mb-0'>Dogs Name</Label><br/>
            <Label>{data?.dogs}</Label>
          </Col>
          <Col xs={4}>
            <Label className='mb-0'>Due Amount</Label><br/>
            <Label>{data?.due_amount}</Label>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleConfirm}
          color="primary"
          className="waves-effect"
        >
          Record Payment
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RecordPaymentModal