import BookingDetailsList from 'Components/ParentBookingDetails'
import { useEffect, useRef, useState } from 'react'
import { BookingDetailsListTableHeading } from '../../Constants'
import { deleteBookingById, getBookingDetailsDataById, getBookingDetailsListReq, updateBookingById } from '../../Api'
import ViewBookingDetailsData from 'Components/ViewBookingDetailsData'
import EditBookingDetailsData from 'Components/EditBookingDetailsData'
import BookingSitterSearch from 'Components/BookingSitterSearch'
import SweetAlert from 'react-bootstrap-sweetalert'
import CreateNewBooking from 'Components/CreateBooking'
import ApplyingBookingMOdal from 'Components/ApplyingBookingModal'
import RecordPaymentModal from 'Components/RecordPaymentModal'
import SitterStatus from 'Components/statusNote'

const AllBookingDetails = () => {
  const [parentUserId, setParentUserId] = useState('')
  const [bookingId, setBookingId] = useState('')
  const [userData, setUserData] = useState([])
  const [bookingList, setBookingList] = useState([])
  const [bookingData, setBookingData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showEventAlert, setShowEventAlert] = useState(false)
  const [showEventConfirm, setShowEventConfirm] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  })
  const { currentPage, totalSize } = paginationValue
  const [isBookingViewModal, setIsBookingViewModal] = useState(false)
  const [isBookingEditModal, setIsBookingEditModal] = useState(false)
  const [isBookingSearchModal, setIsBookingSearchModal] = useState(false)
  const [isApplyBookingModal, setIsApplyBookingModal] = useState(false)
  const [isRecordPaymemtModal, setIsRecordPaymemtModal] = useState(false)
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [filteredData, setFilteredData] = useState({
    count: 0,
    pageSize: ''
  })
  const { count, pageSize } = filteredData
  const [searchData, setSearchData] = useState('')
  const [createBookingModal, setCreateBookingModal] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')

  const handleBookingDetailList = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getBookingDetailsListReq(queryParams)
      setBookingList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setFilteredData({ ...filteredData, count: data.data.count })
      setIsLoading(false)
      return data
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
  }, [])

  const handleTablePageChangeTags = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let start_month_sort = null
    if (sortField == 'service_start_month'){
      start_month_sort = sortOrder
    }
    handleBookingDetailList({
      page: page,
      start_month_sort,
      search: searchData,
      page_size: pageSize,
      status: selectedStatus
    })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getBookingDetailsDataById(UserId)
      setBookingData(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewBookingData = (bookingId) => {
    getDataByUserId(bookingId)
    setIsBookingViewModal(true)
  }

  const handleEditBookingData = (bookingId, parent_user_id) => {
    setBookingId(bookingId)
    setParentUserId(parent_user_id)
    setIsBookingEditModal(true)
  }

  const handleSitterBookingData = (bookingId, parent_user_id) => {
    setBookingId(bookingId)
    setParentUserId(parent_user_id)
    getDataByUserId(bookingId)
    setIsBookingSearchModal(true)
  }

  const deleteBooking = () => {
    deleteBookingById(bookingId).then((res)=> {
      res.data.response_code == 80 && 
      (setShowEventConfirm(true), handleBookingDetailList({ 
        page: 1, start_month_sort: '', search: '', page_size: '', status: ''
      }))
    })
  }

  const handleDeleteBookingData = (bookingId) => {
    setBookingId(bookingId)
    setShowEventAlert(true)
  }

  const handleDownloadCsvData = () => {
    handleBookingDetailList({
      page: 1,
      search: searchData,
      start_month_sort: '',
      page_size: 200,
      status: selectedStatus
    }).then((res) => {if(res.response_code == 80){
      // setCsvData(res.data.results)
      setIsCsvLoaded(true)
    }})
  }

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      BookingID: obj.booking_id, RecievedOn: obj.created_on, ParentName: obj.parent_full_name, Email: obj.parent_email,
      Dogs: obj.dogs, ServiceType: obj.service_type, Dates: obj.dates,
      Location: obj.location_type }))
    return data
  }

  const handleFilter = () => {
    handleBookingDetailList({
      page: 1,
      start_month_sort: '',
      search: searchData,
      page_size: pageSize,
      status: selectedStatus
    }).then(() => setIsCsvLoaded(false))
  }

  const handleSearch = (e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleBookingDetailList({
      page: 1,
      start_month_sort: '',
      search: val,
      page_size: pageSize,
      status: selectedStatus
    })
    setIsCsvLoaded(false)
  }

  const handleChangeFilter = (e) => {
    setSelectedStatus(e.target.value)
    handleBookingDetailList({
      page: 1,
      start_month_sort: '',
      search: searchData,
      page_size: pageSize,
      status: e.target.value
    })
  }

  const handleAllApplyingBooking = (data) => {
    setUserData(data)
    setIsApplyBookingModal(true)
  }

  const handleRecordPayment = (data) => {
    setUserData(data)
    setIsRecordPaymemtModal(true)
  }

  const handleUpdateStatus = (list) => {
    setBookingId(list.id)
    setUpadtedStatus(list.admin_notes)
    setIsNotesModalOpen(true)
  }

  const handleUpdateStatusReq = () => {
    updateBookingById({ 'admin_notes': upadtedStatus }, bookingId).then((res) => {
      res.data.response_code == 80 && setIsNotesModalOpen(false), setUpadtedStatus(''),
      handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
    })
  }

  return (
    <>
      <BookingDetailsList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={bookingList}
        handleViewBookingData={handleViewBookingData}
        handleEditBookingData={handleEditBookingData}
        handleSitterBookingData={handleSitterBookingData}
        handleDeleteBookingData={(handleDeleteBookingData)}
        handleUpdateStatus={handleUpdateStatus}
        tableHeading={BookingDetailsListTableHeading}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        renderTable={() => handleBookingDetailList({ page: 1,start_month_sort: '', search: '', page_size: '', status: '' })}
        dataCount={count}
        handleFilter={handleFilter}
        csvData={filterCsvData(bookingList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
        handleSearch={handleSearch}
        handleCreateBooking={() => setCreateBookingModal(true)}
        handleChangeFilter={handleChangeFilter}
        handleClearFilter={() => {
          handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
          setSelectedStatus('')
        }}
        filterValue={selectedStatus}
        handleAllApplyingBooking={handleAllApplyingBooking}
        handleRecordPayment={handleRecordPayment}
      />

      {isBookingViewModal && <ViewBookingDetailsData 
        isOpen={isBookingViewModal}
        handleClose={() => {setIsBookingViewModal(false); setBookingData([])}}
        bookingData={bookingData}
      />}

      
      {isBookingEditModal && <EditBookingDetailsData 
        isOpen={isBookingEditModal}
        handleClose={() => {setIsBookingEditModal(false); setBookingData([])}}
        bookingId={bookingId}
        parentUserId={parentUserId}
      />}

      {isApplyBookingModal && <ApplyingBookingMOdal 
        isOpen={isApplyBookingModal}
        handleClose={() => {setIsApplyBookingModal(false); setBookingData([])}}
        data={userData}
        reRenderTable={() => { 
          handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
          setSelectedStatus('')
        }}
      />}

      {isRecordPaymemtModal && <RecordPaymentModal 
        isOpen={isRecordPaymemtModal}
        handleClose={() => {setIsRecordPaymemtModal(false); setBookingData([])}}
        data={userData}
        reRenderTable={() => { 
          handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
          setSelectedStatus('')
        }}
      />}

      {isBookingSearchModal && <BookingSitterSearch 
        isOpen={isBookingSearchModal}
        bookingId={bookingId}
        bookingData={bookingData}
        handleClose={() => { 
          setIsBookingSearchModal(false)
          setBookingData([])
          handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })}}
      />}

      {isNotesModalOpen && <SitterStatus
        title={'Booking Notes'}
        isOpen={isNotesModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={(e) => setUpadtedStatus(e.target.value)}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => {setIsNotesModalOpen(false), setUpadtedStatus('')}}
      />}

      {createBookingModal && (
        <CreateNewBooking
          isOpen={createBookingModal}
          handleClose={() => setCreateBookingModal(false)}
          handleBookingDetailList={() => {
            handleBookingDetailList({ page: 1, start_month_sort: '', search: '', page_size: '', status: '' })
          }}
        />
      )}

      {showEventAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteBooking}
          onCancel={() => setShowEventAlert(false)}
        ></SweetAlert>
      ) : null}

      {showEventConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowEventAlert(false)
            setShowEventConfirm(false)
          }}
        >
          Booking has been Deleted
        </SweetAlert>
      ) : null}

    </>
  )
}

export default AllBookingDetails