import { getLocal } from './localStorage'

export const userRole = () => {
  return getLocal('role')
}

export const weekDaysArr = [
  {
    key: 'sunday',
    label: 'Sun'
  },
  {
    key: 'monday',
    label: 'Mon'
  },
  {
    key: 'tuesday',
    label: 'Tue'
  },
  {
    key: 'wednesday',
    label: 'Wed'
  },
  {
    key: 'thursday',
    label: 'Thur'
  },
  {
    key: 'friday',
    label: 'Fri'
  },
  {
    key: 'saturday',
    label: 'Sat'
  }
]