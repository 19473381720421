import { Fragment } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import {
  Row,
  // Alert,
  Container,
  Col,
} from 'reactstrap'

import { AuthRoutes } from './Constants/routes'
import { headingSettings } from './Constants/settings'
import { assets } from 'assets/assests'

const Authentication = () => {
  // const match = useRouteMatch()
  const location = useLocation()

  return (
    <Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters w-100 m-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <img width="160px" src={assets.DogstayLogo} alt="Dogstays"/>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            {/* @ts-ignore */}
                            {headingSettings[location.pathname] &&
                              // @ts-ignore
                              headingSettings[location.pathname].heading}
                          </h4>
                          <p className="text-muted">
                            {/* @ts-ignore */}
                            {headingSettings[location.pathname] &&
                              // @ts-ignore
                              headingSettings[location.pathname].subHead}
                          </p>
                        </div>
                        <Routes>
                          {AuthRoutes.map(({ path, Component }) => (
                            <Route
                              element={<Component />}
                              path={path}
                              key={path}
                            />
                          ))}
                        </Routes>

                        <div className="mt-5 text-center">
                          <p>© 2022 Dogstays.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8} className="p-0">
              <div className="authentication-bg">
                {/* <div className="bg-overlay"></div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default Authentication
