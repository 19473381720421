import { Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ViewBookingLocationComp = ({ bookingData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  return (
    <>
      {bookingData.location_type.includes('parent_location') &&
        <Row>
          <p className='m-0'>Parent Address:
            <span className='font-bold'> {bookingData.parent_address?.street_name + ' ' + bookingData.parent_address?.city + ' ' +
              bookingData.parent_address?.country + ' ' + bookingData.parent_address?.postal_code}</span></p>
        </Row>}

      {bookingData.location_type.includes('other_address') &&
        <Row>
          <p className='m-0'>Other Address:
            <span className='font-bold'> {bookingData.manual_address?.street_name + ' ' + bookingData.manual_address?.city + ' ' +
              bookingData.manual_address?.country + ' ' + bookingData.manual_address?.postal_code}</span></p>
        </Row>}
      {bookingData.location_type.includes('sitter_location') &&
        <Row>
          <h6 className='font-bold mt-3'>At a dog sitter&apos;s home</h6>
          <p className='m-0'>Is it OK if the sitter`s home has a cat:
            <span className='font-bold'> {bookingData.comfortable_with_sitter_cat}</span></p>
          <p className='m-0 mb-3'>Additional Info(cat):
            <span className='font-bold'> {bookingData.comfortable_with_sitter_cat_text}</span></p>
          <p className='m-0'>Is it OK if the sitter`s home has a children:
            <span className='font-bold'> {bookingData.comfortable_with_sitter_children}</span></p>
          <p className='m-0 mb-3'>Additional Info(children):
            <span className='font-bold'> {bookingData.comfortable_with_sitter_children_text}</span></p>
          <p className='m-0'>Is it OK if the sitter`s home has a dog:
            <span className='font-bold'> {bookingData.comfortable_with_sitter_dog}</span></p>
          <p className='m-0 mb-3'>Additional Info(dog):
            <span className='font-bold'> {bookingData.comfortable_with_sitter_dog_text}</span></p>
        </Row>}
    </>
  )
}

export default ViewBookingLocationComp