import { Route, Routes, Navigate } from 'react-router-dom'

import { UsersRoutes } from './Constants/routes'

const Users = () => {
  return (
    <>
      <Routes>
        {UsersRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate
              to={UsersRoutes[0]?.routePath || '/dashboard'}
              replace
            />
          }
        />
      </Routes>
    </>
  )
}

export default Users
