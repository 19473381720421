import AllParentUsers from '../Views/AllParents'
import AllBookingDetails from '../Views/BookingDetails'
import UpdateBookingDetails from '../Views/UpdateBookings'

export const parentsRoutes = [
  {
    Component: AllParentUsers,
    path: '/all',
    exact: true,
    routePath: '/dashboard/parent/all',
    name: 'All Parents',
  }
]