import axios from 'axios'

const subDomain = 'https://api.dogstays.lu/'

export const getUsersPaymentListData = ({ page, search, status, page_size }) => {
  return axios.get(`${subDomain}administrator/payment/list?page=${page}&search=${search}` +
  `&status=${status}&page_size=${page_size}`) 
}

export const getUsersRefundListData = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/refund/pending/request/list?page=${page}&search=${search}`) 
}

export const initiateRefundData = (payload, id ) => {
  return axios.put(`${subDomain}administrator/refund/request/initiate/${id}`, payload) 
}

export const getAllUsersRefundListData = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/refund/request/list?page=${page}&search=${search}`) 
}

export const getPendingInvoiceListData = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/invoice/pending?page=${page}&search=${search}`) 
}

export const regenerateInvoiceData = ( id ) => {
  return axios.put(`${subDomain}administrator/invoice/regenerate/${id}`) 
}

export const updateInvoiceData = (payload, id) => {
  return axios.put(`${subDomain}administrator/invoice/update/${id}`, payload)
}

export const capturePayment = (id) => {
  return axios.put(`${subDomain}administrator/booking/manual-capture/payment/${id}`)
}

export const getPaymentConfigListData = () => {
  return axios.get(`${subDomain}administrator/config/get`) 
}

export const updatePaymentConfigListData = (payload) => {
  return axios.put(`${subDomain}administrator/config/edit`, payload)
}

export const resendInvoice = (id) => {
  return axios.post(`${subDomain}administrator/payment/resend/invoice/${id}`) 
}

export const updatePaymentNotes = (payload, id) => {
  return axios.put(`${subDomain}administrator/payment/update/notes/${id}`, payload)
}