import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'


const NewDateTimePicker = ({ selectedDates, updatedDateTime, isEditMode }) => {
  const [dateObj, setDateObj] = useState({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: ''
  })
  const [isDisabled, setIsDisabled] = useState(true)

  const { startDate, startTime, endDate, endTime } = dateObj

  useEffect(() => {
    isEditMode && setIsDisabled(false)
  }, [isEditMode])

  useEffect(() => {
    setDateObj({
      ...dateObj,
      startDate: selectedDates.startDate,
      startTime:  selectedDates.startTime,
      endDate: selectedDates.endDate,
      endTime: selectedDates.endTime
    })
  }, [selectedDates])

  useEffect(() => {
    updatedDateTime(dateObj)
  }, [dateObj])
  


  return(
    <Row>
      <Col xs={6}>
        <FormGroup>
          <Label>Start Date</Label>
          <Input
            value={startDate}
            onChange={(e) => setDateObj({ ...dateObj, startDate: e.target.value })}
            type="date"
            className="form-control"
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label>Start Time</Label>
          <br/>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              ampm={false}
              value={dayjs(`${startDate}T${startTime}`)}
              onChange={(e) => setDateObj({ ...dateObj, startTime: e.format('HH:mm') })}
              disabled={isDisabled}
            />
          </LocalizationProvider>
        </FormGroup>
      </Col>
      <Col xs={6}>
        <FormGroup>
          <Label>End Date</Label>
          <Input
            value={endDate}
            onChange={(e) => setDateObj({ ...dateObj, endDate: e.target.value })}
            type="date"
            className="form-control"
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label>End Time</Label>
          <br/>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              ampm={false}
              value={dayjs(`${endDate}T${endTime}`)}
              onChange={(e) => setDateObj({ ...dateObj, endTime: e.format('HH:mm') })}
              disabled={isDisabled}
            />
          </LocalizationProvider>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default NewDateTimePicker