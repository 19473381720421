import UsersMeetingList from '../Views/AllMeetings'
import UsersPendingMeetingList from '../Views/PendingMeetings'

export const meetingsRoutes = [
  {
    Component: UsersMeetingList,
    path: '/all',
    exact: true,
    routePath: '/dashboard/meeting/all',
    name: 'All Meetings',
  },
  {
    Component: UsersPendingMeetingList,
    path: '/pending',
    exact: true,
    routePath: '/dashboard/meeting/pending',
    name: `Meeting Changes Request (${JSON.parse(localStorage.getItem('dataCount'))?.meeting_change_count || '0'})`,
  },
]