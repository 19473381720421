import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const RefundList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleApporoveRefundReq,
  handleRejectRefundReq
}) => {
  const [rowData, setRowData] = useState([])

  const viewUsersFormatter = (cell, row) => {
    return (
      <>
        {['super_admin'].includes(userRole()) && <div className='d-flex justify-content-evenly'>
          <div style={{ textAlign: 'center' }} key={row.id}>
            <i
              className="dripicons-checkmark text-success h4 cursor-pointer mr-2"
              onClick={() => handleApporoveRefundReq(row.id, row.amount)}
            />
          </div>
          <div style={{ textAlign: 'center' }} key={row.id}>
            <i
              className="dripicons-cross text-danger h4 cursor-pointer mr-2"
              onClick={() => handleRejectRefundReq(row.id)}
            />
          </div>
        </div>}
      </>
    )
  }

  useEffect(() => {
    {['super_admin'].includes(userRole()) && tableHeading.push({
      text: 'Action',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewUsersFormatter,
    })}
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={6}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Refund Pending</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height:'35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default RefundList
