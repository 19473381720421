import axios from 'axios'
import baseDomain, { loginAPI } from 'Constants/authServerRoutes'
import { setLocal } from 'Utils/localStorage'


export const loginUserAPI = (data) => {
  return axios.post(`${baseDomain}${loginAPI}`, data).then((res) => {
    if(res.data.response_code === 80){
      setLocal('token', res.data.data.token)
      setLocal('role', res.data.data.role)
    }
    if(res.data.response_code === 90){
      console.log(res.data.developer_message)
    }
  })
}

// export const fetchUserDataAPI = () => {
//   return axios.get(`${baseDomain}${getMe}`)
// }

