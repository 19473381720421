import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProfileMenu } from 'Components'
import { assets } from 'assets/assests'
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap'
import { userRole } from 'Utils/helper'


const Header = ({ userName, email, handleLogout, handleAdmin }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true)
  }

  const style = {
    width: '170px',
  }
  return (
    <Fragment>
      <header id="page-topbar" className='hide-mob'>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img className="lms-logo" style={style} src={assets.DogstayLogo} alt="DogStay" />
                </span>
                <span className="logo-lg">
                  <img className="lms-logo" style={style} src={assets.DogstayLogo} alt="DogStay" />
                </span>
              </Link>

              <Link to="#" className="logo logo-light">
                <span className="logo-sm">DogStays</span>
                <span className="logo-lg">DogStays</span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <ProfileMenu
              userName={userName}
              email={email}
              handleLogout={handleLogout}
              handleAdmin={handleAdmin}
            />
          </div>
        </div>
      </header>

      <div className='hide-desk'>
        <Navbar color="light" light expand="md">
          <NavbarBrand ><img className="lms-logo" style={style} src={assets.DogstayLogo} alt="DogStay" /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                {['super_admin', 'admin'].includes(userRole()) && <NavLink href='/dashboard/users/all'>New Sitters</NavLink>}
              </NavItem>
              <NavItem>
                {['super_admin', 'admin'].includes(userRole()) &&
                 <NavLink href='/dashboard/users/partial'>Partial Approved </NavLink>}
              </NavItem>
              <NavItem>
                {['super_admin', 'admin'].includes(userRole())
                 && <NavLink href='/dashboard/users/kyc'>Booking Preferences</NavLink>}
              </NavItem>
              <NavItem>
                <NavLink href='/dashboard/users/approved'>Approved Sitters</NavLink>
              </NavItem>
              <NavItem>
                {['super_admin', 'admin'].includes(userRole())
                 && <NavLink href='/dashboard/users/rejected'>Rejected Sitters</NavLink>}
              </NavItem>
              <NavItem>
                <ProfileMenu
                  userName={userName}
                  email={email}
                  handleLogout={handleLogout}
                  handleAdmin={handleAdmin}
                />
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </Fragment>
  )
}

export default Header
