import {
  Routes,
  Route,
} from 'react-router-dom'
import { MainRoutes } from 'Constants/routes'
import DecisionComponent from 'Components/DecisionComponent'
import './theme.scss'
import './style.scss'
import { useEffect } from 'react'
import { getCount } from 'Views/Dashboard/Views/Users/Users.Apis'

const App = () => {

  useEffect(() => {
    getCount().then((res) => {
      localStorage.setItem('dataCount', JSON.stringify(res.data.data))
    })
  }, [])
  
  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          autoDisplay: false,
          includedLanguages: 'en,fr,de'
        },
        'google_translate_element'
      )
    }
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [])
  return (
    <>
      <div id="google_translate_element"></div>
      <Routes>
        {MainRoutes.map(({ Component, path }) => (
          <Route element={<Component />} path={path} key={path} />
        ))}
        <Route element={<DecisionComponent />} path="*" />
        {/* <Route path="*" element={<Navigate to="/auth" replace />} /> */}
      </Routes>
    </>

  )

}
export default App
