import { useEffect, useRef, useState } from 'react'
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import DatePicker from 'react-multi-date-picker'

const EditDaycareData = ({ data, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const ref = useRef()
  const [daycareData, setDaycareData] = useState({
    daycare_dates: data.daycare_dates,
    daycare_dates_flexible: data.daycare_dates_flexible,
    other_details: data.other_details
  })
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [modifiedDaycareData, setModifiedDaycareData] = useState([...data.daycare_dates])
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0)

  useEffect(() => {
    const convertDate = data.daycare_dates.map((value) => value.date)
    setDayCareSelectDates([ ...dayCareSelectDates, ...convertDate ])
    // setModifiedDaycareData([ ...modifiedDaycareData, ...data.daycare_dates ])
  }, [])


  const calculateTimeDifference = (startTime, endTime) => {
    if (startTime && endTime) {
      const [hours1, minutes1] = startTime.split(':').map(Number)
      const [hours2, minutes2] = endTime.split(':').map(Number)
      const totalMinutes1 = hours1 * 60 + minutes1
      const totalMinutes2 = hours2 * 60 + minutes2
      let timeDifference = totalMinutes2 - totalMinutes1
      const hoursDifference = Math.floor(timeDifference / 60)
      const minutesDifference = timeDifference % 60
      return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
    } else return
  }

  useEffect(() => {
    setDaycareData({ ...daycareData, daycare_dates: modifiedDaycareData })
  }, [modifiedDaycareData])

  useEffect(() => {
    updatedData(daycareData)
  }, [daycareData])

  const dayType = []


  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if(item.day_type !== ''){
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map(day => {
        if (day === 'Half Day') {return 0.5}
        else if (day === 'Full Day') {return 1}
        else if (day === 'One Half') {return 1.5}
        return day
      })
      let totalDaysSum = 0
      convertedArray.forEach( num => {totalDaysSum += num})
      setDaycareDaysTime(totalDaysSum)
      return
    })
  }, [modifiedDaycareData])


  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map(
      (value) => value.year + '-' + (value.month >=10 ? value.month : `0${value.month}` ) + '-' + value.day)
    // const newDateObject = convertDate.filter(date => !modifiedDaycareData.filter(item => item.date === date))
    let newDateObject = modifiedDaycareData.map(element => element.date)
    let result = convertDate.filter(element => !newDateObject.includes(element))
    const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date))
    const modifiedJson = result.map((item) => {
      const data = {
        date: item,
        start_time: '',
        end_time: '',
        total_hours: '',
        day_type: '' // Full or half day
      }
      mappedData.push(data)
    })
    setDayCareSelectDates(convertDate)
    mappedData.length > 0 ? 
      setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ]) : setModifiedDaycareData(filteredArr)
  }

  const calculateHoursToMins = (hours) => {
    if (hours) {
      const [hour, minutes] = hours.split(':').map(Number)
      const totalMinutes = hour * 60 + minutes
      return totalMinutes
    }
  }

  return (
    <>
      <Col lg='4'>
        <FormGroup>
          <Label>Select new dates</Label>
          <DatePicker ref={ref} className='d-block' multiple dateFormat="YYYY-MM-DD" shouldCloseOnSelect={true}
            value={dayCareSelectDates}
            onChange={handledDayCareMultipleDates}
          >
            <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
              color='primary' onClick={() => ref.current.closeCalendar()}>
              close
            </Button>
          </DatePicker>
        </FormGroup>
      </Col>

      {modifiedDaycareData.map((item, index) => {
        return (
          <Row key={index} className='align-items-center border rounded m-1 p-1 bg-light'>
            <Col lg='3'>
              <FormGroup>
                <Label>Date</Label>
                <Input type="date" className="form-control"
                  disabled
                  value={item.date}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Start Time</Label>
                <Input type="time" className="form-control"
                  onChange={(time) => {
                    const updatedData = {
                      ...item, start_time: time.target.value,
                      total_hours: calculateTimeDifference(time.target.value, item.end_time),
                      day_type: calculateHoursToMins(calculateTimeDifference(time.target.value, item.end_time)) == 0 ? '' 
                        : calculateHoursToMins(calculateTimeDifference(time.target.value, item.end_time)) > 601 ? 'One Half' 
                          : calculateHoursToMins(calculateTimeDifference(time.target.value, item.end_time)) > 301 ?
                            'Full Day' : 'Half Day'
                    }
                    setModifiedDaycareData((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  value={item.start_time}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>End Time</Label>
                <Input type="time" className="form-control"
                  onChange={(time) => {
                    const updatedData = {
                      ...item, end_time: time.target.value,
                      total_hours: calculateTimeDifference(item.start_time, time.target.value),
                      day_type: calculateHoursToMins(calculateTimeDifference(item.start_time, time.target.value)) == 0 ? '' 
                        : calculateHoursToMins(calculateTimeDifference(item.start_time, time.target.value)) > 601 ? 'One Half' 
                          : calculateHoursToMins(calculateTimeDifference(item.start_time, time.target.value)) > 301 ? 
                            'Full Day' : 'Half Day'
                    }
                    setModifiedDaycareData((prevData) => {
                      const newData = [...prevData]
                      newData[index] = updatedData
                      return newData
                    })
                  }}
                  value={item.end_time}
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Time Diff</Label>
                <Input type="text" className="form-control"
                  value={item.total_hours}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg='2'>
              <FormGroup>
                <Label>Day Type</Label>
                <Input type="text" className="form-control"
                  value={item.day_type}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg='1'>
              <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"
                onClick={() => {
                  const calenderItems = [...dayCareSelectDates]
                  const modifyItems = [...modifiedDaycareData]
                  calenderItems.splice(index, 1)
                  modifyItems.splice(index, 1)
                  setModifiedDaycareData(modifyItems)
                  setDayCareSelectDates(calenderItems)
                }}
              />
            </Col>
          </Row>
        )
      })}
      <p className='font-bold text-end'>Total days: {daycareDataDaysTime}</p>
      <Row className='mt-4'>
        <Col lg='6' style={style}>
          <FormGroup>
            <Label>Daycare dates are flexible?</Label>
            <Input value={daycareData.daycare_dates_flexible} type="select" className="form-control"
              onChange={(e) => setDaycareData({ ...daycareData, daycare_dates_flexible: e.target.value })}>
              <option selected disabled value=''>Select</option>
              <option value='true'>No, my dates cannot change</option>
              <option value='false'>Yes, my dates can be adjusted for the right dog sitter</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg='6' style={style}>
          <FormGroup>
            <Label>Additional Details</Label>
            <textarea value={daycareData.other_details} type="text" className="form-control"
              onChange={(e) => setDaycareData({ ...daycareData, other_details: e.target.value })} />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default EditDaycareData