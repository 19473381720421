import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input, Button } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'
import CsvDownloadButton from 'react-json-to-csv'


const PaymentList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleClearFilter,
  handleChangeFilter,
  filterValue,
  handleUpdateStatus,
  handleResendInvoice,
  csvData,
  isCsvLoaded,
  handleCallDataForCsv,
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row.id}>
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <a href={row.invoice_link} 
          rel='noreferrer' download target='_blank'>
          <i className=" ri-file-download-fill text-success h4 cursor-pointer mx-2" /></a>}
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className=" ri-send-plane-fill text-warning h4 cursor-pointer mx-2"
          onClick={() => handleResendInvoice(row.id)}
        />}
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className="ri-message-3-fill text-primary h4 cursor-pointer mx-2"
          onClick={() => handleUpdateStatus(row, row.id)}
        />}
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])


  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className='align-items-center'>
              <Col xs={3}>
                <div className="page-title-box d-flex align-items-center justify-content-between p-0">
                  <h3 className="m-0">Payment List</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height:'35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
              <Col xs={2}>
                <Label>Status</Label>
                <Input
                  type="select"
                  value={filterValue}
                  onChange={handleChangeFilter}
                  className="form-control"
                >
                  <option hidden value=''>Select</option>
                  <option value='successful'>successful</option>
                  <option value='failed'>failed</option>
                  <option value='initiated'>initiated</option>
                </Input>
              </Col>
              <Col xs={1} className="text-center">
                <Button onClick={handleClearFilter}>Clear</Button>
              </Col>
              {isCsvLoaded ? (['super_admin', 'admin'].includes(userRole()) && <Col xs={1}>
                <CsvDownloadButton data={csvData} /></Col>) : 
                <Col xs={1}><Button onClick={handleCallDataForCsv}><i className='ri-download-2-line' ></i></Button></Col>}
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PaymentList
