import axios from 'axios'


const subDomain = 'https://api.dogstays.lu/'


export const getParentUsersListReq = ({ page, search, hear_from_source, preferred_languages, page_size }) => {
  return axios.get(`${subDomain}administrator/parent/list?page=${page}&search=${search}` + 
  `&hear_from_source=${hear_from_source}&preferred_languages=${preferred_languages}&page_size=${page_size}`) 
}

export const getParentDataByIdReq = (UserId) => {
  return axios.get(`${subDomain}administrator/parent/profile/detail/${UserId}`)
}

export const updateParentInfoReq = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/parent/edit/${UserId}`,payload) 
}

export const updateAddressInfoReq = (payload, id) => {
  return axios.put(`${subDomain}administrator/parent/edit/address/${id}`,payload) 
}

export const getBookingDetailsListReq = ({ page, start_month_sort, search, page_size, status }) => {
  return axios.get(`${subDomain}administrator/booking/list?page=${page}&start_month_sort=${start_month_sort}` + 
  `&search=${search}&page_size=${page_size}&status=${status}`) 
}

export const getBookingDetailsDataById = (id) => {
  return axios.get(`${subDomain}administrator/booking/details/${id}`) 
}

export const getParentAddressById = (id) => {
  return axios.get(`${subDomain}administrator/parent/get/address/${id}`) 
}

export const getParentDogsListById = (id) => {
  return axios.get(`${subDomain}administrator/parent/get/dogs/${id}`) 
}

export const updateBookingById = (payload, id) => {
  return axios.put(`${subDomain}administrator/booking/edit/${id}`,payload) 
}

export const getBookingSearchResults = (id, distance_sort, { page }) => {
  if (distance_sort){
    return axios.get(`${subDomain}administrator/booking/search/sitterv2/${id}?page=${page}&distance_sort=${distance_sort}`)
  }
  return axios.get(`${subDomain}administrator/booking/search/sitterv2/${id}?page=${page}`) 
}

export const getBookingSearchResultsViaFilters = ({
  have_your_own_dog,
  have_your_own_cat, 
  have_children, 
  is_inactive, 
  size_of_dog,
  age_of_dog,
  preferred_languages,
  daily_schedule,
  floor,
  home_has_elevator,
  comfortable_with_non_toilet_trained,
  comfortable_with_two_dogs,
  comfortable_with_separation_anxiety,
  outdoor_spaces,
  sensitivity,
  has_driving_license,
  has_car
}) => {
  return axios.get(
    `${subDomain}administrator/sitter/list?` +
      `&preferred_languages=${preferred_languages}&daily_schedule=${daily_schedule}&floor=${floor}` +
      `&home_has_elevator=${home_has_elevator}&have_your_own_cat=${have_your_own_cat}` +
      `&size_of_dog=${size_of_dog}&age_of_dog=${age_of_dog}&have_children=${have_children}` +
      `&comfortable_with_non_toilet_trained=${comfortable_with_non_toilet_trained}` +
      `&comfortable_with_two_dogs=${comfortable_with_two_dogs}&is_inactive=${is_inactive}` +
      `&comfortable_with_separation_anxiety=${comfortable_with_separation_anxiety}` +
      `&outdoor_spaces=${outdoor_spaces}&sensitivity=${sensitivity}&have_your_own_dog=${have_your_own_dog}` +
      `&has_driving_license=${has_driving_license}&has_car=${has_car}`
  )
}

export const createDog = (payload, parentId) => {
  return axios.post(`${subDomain}administrator/add/dog/${parentId}`, payload) 
}

export const getDogList = (id) => {
  return axios.get(`${subDomain}administrator/dog/list/${id}`) 
}

export const getDogDetailsById = (id) => {
  return axios.get(`${subDomain}administrator/dog/details/${id}`) 
}

export const updateDogById = (payload, id) => {
  return axios.put(`${subDomain}administrator/dog/edit/${id}`,payload) 
}

export const deleteDogById = (id) => {
  return axios.put(`${subDomain}administrator/dog/delete/${id}`) 
}

export const sendNotificationReqViaPlatform = (payload, id) => {
  return axios.post(`${subDomain}administrator/booking/${id}/share-details-with-sitters`, payload) 
}

export const updateBookingStatusById = (bookingId, payload) => {
  return axios.put(`${subDomain}administrator/booking/status/update/${bookingId}`, payload) 
}

export const sharedToSitters = (id) => {
  return axios.get(`${subDomain}administrator/booking/shared_to_sitters/list/${id}`) 
}

export const getMeetingLocationByBookingId = (id) => {
  return axios.get(`${subDomain}administrator/booking/meeting_location_options/${id}`) 
}

export const deleteBookingById = (id) => {
  return axios.put(`${subDomain}administrator/booking/delete/${id}`) 
}

export const deleteParentById = (id) => {
  return axios.put(`${subDomain}administrator/parent/delete/${id}`) 
}

export const getUpdateBookingDetailsListReq = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/booking/update/request/list?page=${page}` + 
  `&search=${search}`) 
}

export const updateBookingInfoById = (payload, id) => {
  return axios.put(`${subDomain}administrator/booking/update/dates/${id}`, payload) 
}

export const updateCareFormInfo = (payload, id) => {
  return axios.put(`${subDomain}administrator/dog/update_dog_form/${id}`,payload) 
}

export const getParentsList = ( search ) => {
  return axios.get(`${subDomain}administrator/booking/parent/search?search=${search}`) 
}

export const getSittersList = ( search ) => {
  return axios.get(`${subDomain}administrator/booking/sitter/search?search=${search}`) 
}

export const createBooking = (payload) => {
  return axios.post(`${subDomain}administrator/booking/create`, payload) 
}

export const updateBookingAddOnsById = (payload, id) => {
  return axios.put(`${subDomain}administrator/booking/add/addons/${id}`, payload) 
}

export const updateBookingDates = (payload, id) => {
  return axios.put(`${subDomain}administrator/booking/date/update/${id}`, payload) 
}

export const submitDoginfo = (payload, id) => {
  return axios.put(`${subDomain}administrator/booking/submit/doginfo/${id}`, payload) 
}