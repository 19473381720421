import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import ViewBookingLocationComp from './viewLocationComp'

const ViewOvernightData = ({ overnightData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  return (
    <>
      <Row>
        <p className='m-0'>Overnight dates: <span className='font-bold'>{overnightData.dates}</span></p>
        <p className='m-0'>Total Days Count: <span className='font-bold'>{overnightData.total_day_count}</span></p>
        <p className='m-0'>Additional Details: <span className='font-bold'>{overnightData.other_details}</span></p>
      </Row>
      <h5 className='my-2' style={{ color: '#e8833a', fontWeight: 500 }}>SERVICE LOCATION&apos;S </h5>
      <ViewBookingLocationComp bookingData={overnightData} />
    </>
  )
}

export default ViewOvernightData