import { updatePaymentConfigListData } from 'Views/Dashboard/Views/Payments/Api'
import { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ViewConfigData = ({ isOpen, configDataList , handleClose, type, reRenderList }) => {

  const [configData, setConfigData] = useState(configDataList)
  const [isDisabled, setIsDisabled] = useState(type)
  
  const style ={
    borderRight: '1px solid #ced4da'
  }

  const handleChangeInput = (e) => {
    setConfigData({ ...configData,
      [e.target.name]: e.target.value })
  }

  const handleUpdate = () => {
    updatePaymentConfigListData(configData).then(() => {
      handleClose()
      reRenderList()
    })
  }


  
  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Payment Config</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Overnight Dog Parent`s</Label>
              <Input value={configData.overnight_dp_price} type="number" className="form-control"
                name="overnight_dp_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Overnight Dog Parent`s Additional Dogs</Label>
              <Input value={configData.additional_overnight_dp_price} type="number" className="form-control"
                name="additional_overnight_dp_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Overnight Dog Sitter`s</Label>
              <Input value={configData.overnight_ds_price} type="number" className="form-control"
                name="overnight_ds_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label> Overnight Dog Sitter`s Additional Dogs</Label>
              <Input value={configData.additional_overnight_ds_price} type="number" className="form-control"
                name="additional_overnight_ds_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Parent`s</Label>
              <Input value={configData.daycare_dp_price} type="number" className="form-control"
                name="daycare_dp_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Parent`s Additional Dogs</Label>
              <Input value={configData.additional_daycare_dp_price} type="number" className="form-control"
                name="additional_daycare_dp_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Parent`s half day</Label>
              <Input value={configData?.daycare_dp_half_price} type="number" className="form-control"
                name="daycare_dp_half_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Parent`s Additional Dogs half day</Label>
              <Input value={configData?.additional_daycare_dp_half_price} type="number" className="form-control"
                name="additional_daycare_dp_half_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Sitter`s</Label>
              <Input value={configData.daycare_ds_price} type="number" className="form-control"
                name="daycare_ds_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Sitter`s Additional Dog</Label>
              <Input value={configData.additional_daycare_ds_price} type="number" className="form-control"
                name="additional_daycare_ds_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Sitter`s half day</Label>
              <Input value={configData?.daycare_ds_half_price} type="number" className="form-control"
                name="daycare_ds_half_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>

          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Daycare Dog Sitter`s Additional Dog half day</Label>
              <Input value={configData?.additional_daycare_ds_half_price} type="number" className="form-control"
                name="additional_daycare_ds_half_price" onChange={handleChangeInput} disabled={isDisabled}/>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {!isDisabled && <Button
          type="button"
          onClick={handleUpdate}
          color="light"
          className="waves-effect"
        >
          Update
        </Button>}
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewConfigData
