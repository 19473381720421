import { useEffect, useState } from 'react'
import PaymentList from 'Components/PaymentList'
import { allRefundListTableHeading, refundListTableHeading } from '../../tableCol.js'
import { getAllUsersRefundListData, getUsersRefundListData, initiateRefundData } from '../../Api.js'
import RefundList from 'Components/RefundList/index.jsx'
import RefundConfirmationDialog from 'Components/RefundConfirmationDialog/index.jsx'
import AllRefundList from 'Components/AllRefundsList/index.jsx'


const UsersAllRefundPaymentList = () => {
  const [usersList, setUsersList] = useState([])
  const [searchData, setSearchData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleGetAllRefundPaymentData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getAllUsersRefundListData(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetAllRefundPaymentData({ page: 1, search: '' })
  }, [])



  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetAllRefundPaymentData({ page, search: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetAllRefundPaymentData({ page:1 , search: val })
  }

  return(
    <>
      <AllRefundList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={allRefundListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
      />

    </>
  )

}

export default UsersAllRefundPaymentList