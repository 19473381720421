import { updateBookingInfoById } from 'Views/Dashboard/Views/Parents/Api'
import { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Input } from 'reactstrap'

const ConfirmDaycareBookingModal = ({ isOpen, handleClose, type, bookingId, handleBookingDetailsDataById, closeModal }) => {
  const [value, setvalue] = useState()
  console.log(type,'typeeee')
  console.log(bookingId, 'bookingId')

  const handleConfirm = () => {
    updateBookingInfoById({
      'type_of_payment': type == 'Additional Invoice' ? 'additional' : 'refund',
      'days_count': value
    }, bookingId).then((res) => {
      handleClose()
      closeModal()
      handleBookingDetailsDataById()
    })
  }

  return(
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <ModalHeader>Confirmation</ModalHeader>
      <ModalBody>
        <Row className='mb-5'>
          {type == 'Refund' ? 
            <p>Refund for how many days?</p> :
            <p>Issue invoice for how many additonal days?</p>}
          <Input type='number' value={value} onChange={(e) => setvalue(e.target.value)}/>
          {type == 'Refund' ? 
            <p>Issue Refund</p> :
            <p>Send additonal invoice</p>}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleConfirm} color="primary" className="waves-effect">Confirm</Button>
        <Button type="button" onClick={handleClose} color="light" className="waves-effect">Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmDaycareBookingModal