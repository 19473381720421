import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { useEffect, useState } from 'react'

const EditHomeVisitData = ({ homeVistData, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const [homeVisitData, setHomeVistData] = useState({
    number_of_home_care_visits: homeVistData.number_of_home_care_visits,
    home_care_activities: homeVistData.home_care_activities
  })
  const { number_of_home_care_visits, home_care_activities } = homeVisitData

  useEffect(() => {
    updatedData(homeVisitData)
  }, [homeVisitData])
  return (
    <>
      <Row>
        <Col lg='6' style={style}>
          <FormGroup>
            <Label>Number of home care visits</Label>
            <Input value={number_of_home_care_visits} type="select" className="form-control"
              onChange={(e) => setHomeVistData({ ...homeVisitData, number_of_home_care_visits: e.target.value })}>
              <option selected disabled value="">Select</option>
              <option value="one">One</option>
              <option value="two">Two</option>
              <option value="three">Three</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg='6' style={style}>
          <FormGroup>
            <Label>What would you like the dog sitter to do for your dog during the visit?</Label>
            <Input value={home_care_activities} type="text" className="form-control"
              onChange={(e) => setHomeVistData({ ...homeVisitData, home_care_activities: e.target.value })} />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default EditHomeVisitData