import { useEffect, useState } from 'react'
import { allPendingInvoiceListTableHeading } from '../../tableCol.js'
import { getPendingInvoiceListData, regenerateInvoiceData, updateInvoiceData } from '../../Api.js'
import PendingInvoiceList from 'Components/Invoice/index.jsx'
import SweetAlert from 'react-bootstrap-sweetalert'

const PendingInvoices = () => {
  const [invoiceList, setInvoiceList] = useState([])
  const [searchData, setSearchData] = useState('')
  const [id, setId] = useState()
  const [selectedItem, setSelectedItem] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleGetPendingInvoiceData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getPendingInvoiceListData(queryParams)
      setInvoiceList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetPendingInvoiceData({ page: 1, search: '' })
  }, [])



  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetPendingInvoiceData({ page, search: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetPendingInvoiceData({ page:1 , search: val })
  }

  const handleRegenerateAction = (id) => {
    setSelectedItem(1)
    setId(id)
    setShowAlert(true)
  }

  const handleAcceptAction = (id) => {
    setSelectedItem(2)
    setId(id)
    setShowAlert(true)
  }

  const handleRejectAction = (id) => {
    setSelectedItem(3)
    setId(id)
    setShowAlert(true)
  }

  const actionBtn = () => {
    selectedItem == 1 && regenerateInvoiceData(id).then(() => {
      handleGetPendingInvoiceData({ page: 1, search: '' })
      setShowAlert(false)
    })
    selectedItem == 2 && updateInvoiceData({
      'status': 'approved'
    },id).then(() => {
      handleGetPendingInvoiceData({ page: 1, search: '' })
      setShowAlert(false)
    })
    selectedItem == 3 && updateInvoiceData({
      'status': 'cancelled'
    },id).then(() => {
      handleGetPendingInvoiceData({ page: 1, search: '' })
      setShowAlert(false)
    })
  }

  return(
    <>
      <PendingInvoiceList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={invoiceList}
        tableHeading={allPendingInvoiceListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
        handleRegenerateAction={handleRegenerateAction}
        handleAcceptAction={handleAcceptAction}
        handleRejectAction={handleRejectAction}
      />

      {showAlert &&
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          reverseButtons={true}
          onConfirm={actionBtn}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      }

    </>
  )

}

export default PendingInvoices