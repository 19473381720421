// const baseDomain = process.env.REACT_APP_AUTH_SERVER_BASE_DOMAIN

const baseDomain = 'https://api.dogstays.lu/'

export const loginAPI = 'administrator/login' // POST	

export const getMe = '/admin/me' // GET Protected

export const refreshTokenAPI = '/admin/refresh'

// eslint-disable-next-line
export default `${baseDomain}`
