import { useState, useEffect } from 'react'
import { Button, Label, FormGroup, Form, Input } from 'reactstrap'
import { useNavigate } from 'react-router'
import { getLocal, isAuthorized } from 'Utils/localStorage'
import { loginUserAPI } from 'Views/Authentication/Auth.apis'


const Login = () => {
  const navigate = useNavigate()

  const [loginCred, setLoginCred] = useState({
    email: '',
    password: '',
  })
  

  useEffect(() => {
    const token = getLocal('token')
    if(token) {
      navigate('/dashboard/users/all')
    }
  }, [])

  const { email, password } = loginCred

  const handelInput = (e) => {
    setLoginCred({
      ...loginCred,
      [e.target.name]: e.target.value,
    })
  }

  const handelLogin = async(e) => {
    e.preventDefault()
    await loginUserAPI({
      email,
      password
    })
    window.location.reload(true)
  }

  return (
    <div className="p-2 mt-3">
      <Form className="form-horizontal" onSubmit={handelLogin}>
        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-user-2-line auti-custom-input-icon"></i>
          <Label htmlFor="email">Email</Label>
          <Input
            name="email"
            value={email}
            onChange={handelInput}
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter Email"
          />
        </FormGroup>

        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-lock-2-line auti-custom-input-icon"></i>
          <Label htmlFor="userpassword">Password</Label>
          <Input
            name="password"
            value={password}
            onChange={handelInput}
            type="password"
            className="form-control"
            id="userpassword"
            placeholder="Enter password"
          />
        </FormGroup>

        {/* {errorMessage && (
          <div
            style={{
              width: '100%',
              padding: '5px',
              color: 'red',
              margin: '-20px 0 -20px 0',
            }}
          >
            {errorMessage}
          </div>
        )} */}
        <div>
          <p className='term-para'>By clicking Register, you agree
            to the <span className='terms-policy'>Terms and Conditions & Privacy Policy</span> of DogStays</p>
        </div>

        <div className="mt-4 text-center">
          <Button
            className="w-md waves-effect waves-light login-btn"
            type="submit"
          >
            Log In
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Login
