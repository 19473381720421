import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ParentDogsList = ({ isOpen, handleClose, dogListData, handleCreateNewDog, handleViewDogInfo }) => {

  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Parent Dog`s List</ModalHeader>
      <ModalBody>
        <Button type="button"onClick={handleCreateNewDog} color="primary" className="waves-effect">Create Dog</Button>
        <div className='d-flex gap-3 justify-content-center'>
          {dogListData && dogListData.map((item) => {
            return (
              <div  key={item.dog_id} style={{ cursor: 'pointer' }} onClick={() => handleViewDogInfo(item.dog_id)}>
                <img style={{ width:'150px', height: '150px', borderRadius: '50%', border: '4px solid #f78121' }}
                  src={item.photos[0]}/>
                <p className='text-center'>{item.name}</p>
              </div>
            )
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ParentDogsList