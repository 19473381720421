import AllBookingDetails from '../../Parents/Views/BookingDetails'
import UpdateBookingDetails from '../../Parents/Views/UpdateBookings'


export const BookingRoutes = [
  {
    Component: AllBookingDetails,
    path: '/all',
    exact: true,
    routePath: '/dashboard/booking/all',
    name: 'All Bookings',
  },
  {
    Component: UpdateBookingDetails,
    path: '/edit',
    exact: true,
    routePath: '/dashboard/booking/edit',
    name: `Booking Changes Requested (${JSON.parse(localStorage.getItem('dataCount'))?.booking_change_count || '0'})`,
  }
]