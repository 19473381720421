import ParentUserList from 'Components/ParentUserList'
import { ParentUsersListTableHeading } from '../../Constants'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { deleteParentById, getDogDetailsById, getDogList, getParentDataByIdReq,
  getParentUsersListReq, updateParentInfoReq } from '../../Api'
import ViewParentData from 'Components/ViewParentData'
import EditParentProfileData from 'Components/EditParentProfile'
import CreateDogProfile from 'Components/CreateDogProfile'
import ViewDogProfile from 'Components/ViewDogProfile'
import ParentDogsList from 'Components/ViewDogsList'
import SweetAlert from 'react-bootstrap-sweetalert'
import DogCareForm from 'Components/DogCareForm'
import SitterStatus from 'Components/statusNote'

const AllParentUsers = () => {
  const [userId, setUserId] = useState('')
  const [parentsList, setParentsList] = useState([])
  const [parentData, setParentData] = useState([])
  const [csvData, setCsvData] = useState([])
  const [dogListData, setDogListData] = useState([])
  const [dogInfoData, setDogInfoData] = useState([])
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [isParentViewModal, setIsParentViewModal] = useState(false)
  const [isParentEditModal, setIsParentEditModal] = useState(false)
  const [isCreateDogModal, setIsCreateDogModal] = useState(false)  
  const [isViewDogModal, setIsViewDogModal] = useState(false)  
  const [isDogListModal, setIsDogListModal] = useState(false)  
  const [isCareFormModal, setIsCareFormModal] = useState(false)  
  const [showEventAlert, setShowEventAlert] = useState(false)
  const [showEventConfirm, setShowEventConfirm] = useState(false)
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [dogId, setDogId] = useState('')
  const [filteredData, setFilteredData] = useState({
    hearFromSource: '',
    languages: [],
    count: 0,
    pageSize: ''
  })
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { hearFromSource, languages, count, pageSize } = filteredData
  const { currentPage, totalSize } = paginationValue

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const handleParentUsersData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getParentUsersListReq(queryParams)
      setParentsList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setFilteredData({ ...filteredData, count: data.data.count })
      setIsLoading(false)
      return data
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handleParentUsersData({ page: 1, search: '', hear_from_source: '', preferred_languages: '', page_size: '' })
  }, [])

  const handleViewParentData = (userId) => {
    getDataByUserId(userId)
    setUserId(userId)
    setIsParentViewModal(true)
  }

  const handleEditParentData = async(userId) => {
    try {
      getDataByUserId(userId)
      setUserId(userId)
      setIsParentEditModal(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDogAction = (userId) => {
    setUserId(userId)
    getDogList(userId).then((res) => {
      setDogListData(res.data.data)
    })
    setIsDogListModal(true)
  }

  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getParentDataByIdReq(UserId)
      setParentData(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTablePageChangeTags = (type, { page, sizePerPage, sortField, sortOrder }) => {
    handleParentUsersData({
      page,
      search: searchData,
      hear_from_source: hearFromSource,
      preferred_languages: formatMultiSelectData(languages),
      page_size: pageSize
    })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = (e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleParentUsersData({
      page: 1,
      search: val,
      hear_from_source: hearFromSource,
      preferred_languages: formatMultiSelectData(languages),
      page_size: pageSize
    })
    setIsCsvLoaded(false)
  }

  const handleFilter = () => {
    handleParentUsersData({
      page: 1,
      search: searchData,
      hear_from_source: hearFromSource,
      preferred_languages: formatMultiSelectData(languages),
      page_size: pageSize
    }).then(() => setIsCsvLoaded(false))
  }

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      DogParentID: obj.id, DateOfJoining: obj.date_of_joining,
      Source: obj.hear_from_source, FirstName: obj.first_name, LastName: obj.last_name,
      Email: obj.email, Mobile: obj.mobile, Languages: obj.preferred_languages, Dogs: obj.dogs }))
    return data
  }

  const handleDownloadCsvData = () => {
    handleParentUsersData({
      page: 1,
      search: searchData,
      hear_from_source: hearFromSource,
      preferred_languages: formatMultiSelectData(languages),
      page_size: 200
    }).then((res) => {if(res.response_code == 80){
      setCsvData(res.data.results)
      setIsCsvLoaded(true)
    }})
  }

  const handleCreateNewDog = () => {
    setIsDogListModal(false)
    setIsCreateDogModal(true)
  }

  const handleViewDogInfo = (dogId) => {
    setDogId(dogId)
    getDogDetailsById(dogId).then((res) => {
      if(res.data.response_code == 80){
        setDogInfoData(res.data.data)
        setIsDogListModal(false)
        setIsViewDogModal(true)
      }})
  }

  const deleteParent = () => {
    deleteParentById(userId).then((res)=> {
      res.data.response_code == 80 && setShowEventConfirm(true) 
      handleParentUsersData({ page: 1, search: '', hear_from_source: '', preferred_languages: '', page_size: '' })
    })
  }

  const handleDeleteParentData = (parentId) => {
    setUserId(parentId)
    setShowEventAlert(true)
  }

  const handleViewDogCareForm = () => {
    setIsViewDogModal(false)
    setIsCareFormModal(true)
  }

  const handleUpdateStatus = (list) => {
    setUpadtedStatus(list.admin_notes)
    setUserId(list.user_id)
    setIsNotesModalOpen(true)
  }

  const handleUpdateStatusReq = () => {
    updateParentInfoReq({ 'admin_notes': upadtedStatus }, userId).then((res) => {
      res.data.response_code == 80 && setIsNotesModalOpen(false), setUpadtedStatus(''),
      handleParentUsersData({ page: 1, search: '', hear_from_source: '', preferred_languages: '', page_size: '' })
    })
  }

  return (
    <>
      <ParentUserList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={parentsList}
        tableHeading={ParentUsersListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewParentData={handleViewParentData}
        handleEditParentData={handleEditParentData}
        handleDeleteParentData={handleDeleteParentData}
        handleUpdateStatus={handleUpdateStatus}
        handleDogAction={handleDogAction}
        searchData={searchData}
        handleSearch={handleSearch}
        hearFromSource={hearFromSource}
        languages={languages}
        dataCount={count}
        handleHearFromSource={(e) => setFilteredData({ ...filteredData, hearFromSource: e.target.value })}
        handleLanguages={(value) => setFilteredData({ ...filteredData, languages: value })}
        handleFilter={handleFilter}
        csvData={filterCsvData(parentsList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
      />

      <ViewParentData
        isOpen={isParentViewModal}
        parentData={parentData}
        handleClose={() => setIsParentViewModal(false)}
      />

      <EditParentProfileData
        isOpen={isParentEditModal}
        handleClose={() => setIsParentEditModal(false)}
        parentData={parentData}
        userId={userId}
      />
      {isCreateDogModal && <CreateDogProfile
        isOpen={isCreateDogModal}
        handleClose={() => setIsCreateDogModal(false)}
        userId={userId}
      />}
      {isViewDogModal && dogInfoData && <ViewDogProfile
        isOpen={isViewDogModal}
        handleClose={() => setIsViewDogModal(false)}
        userId={userId}
        dogData={dogInfoData}
        handleViewDogCareForm={handleViewDogCareForm}
      />}
      {isDogListModal && <ParentDogsList
        isOpen={isDogListModal}
        handleClose={() => setIsDogListModal(false)}
        userId={userId}
        dogListData={dogListData}
        handleCreateNewDog={handleCreateNewDog}
        handleViewDogInfo={handleViewDogInfo}
      />}

      {isCareFormModal && <DogCareForm
        isOpen={isCareFormModal}
        handleClose={() => setIsCareFormModal(false)}
        dogId={dogId}
        careFormData={dogInfoData?.dog_info_form}
      />}

      {isNotesModalOpen && <SitterStatus
        isOpen={isNotesModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={(e) => setUpadtedStatus(e.target.value)}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => {setIsNotesModalOpen(false), setUpadtedStatus('')}}
      />}


      {showEventAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteParent}
          onCancel={() => setShowEventAlert(false)}
        ></SweetAlert>
      ) : null}

      {showEventConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowEventAlert(false)
            setShowEventConfirm(false)
          }}
        >
          Parent has been Deleted
        </SweetAlert>
      ) : null}
    </>
  )
}

export default AllParentUsers