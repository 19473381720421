import axios from 'axios'


const subDomain = 'https://api.dogstays.lu/'


export const getSitterPendingListreq = ({ search }) => {
  return axios.get(`${subDomain}administrator/sitter/list/pending?search=${search}`) 
}

export const getSitterPendingKycListreq = () => {
  return axios.get(`${subDomain}administrator/sitter/list/kyc/pending`) 
}

export const getSitterReverificationListreq = () => {
  return axios.get(`${subDomain}administrator/sitter/reverification/list`) 
}

export const getRegisteredSitterListreq = ({ page, search, event_updated , is_inactive, sort_by, sort_order, page_size }) => {
  return axios.get(`${subDomain}administrator/sitter/list?page=${page}&search=${search}` + 
  `&event_updated=${event_updated}&is_inactive=${is_inactive}&sort_by=${sort_by}&sort_order=${sort_order}` +
  `&page_size=${page_size}`) 
}

export const getRejectedSitterListreq = ({ page, search }) => {
  return axios.get(`${subDomain}administrator/sitter/reject/list?page=${page}&search=${search}`) 
}

export const deleteRejectedSitterListreq = (id) => {
  return axios.put(`${subDomain}administrator/sitter/delete/${id}`) 
}

export const updateSitterInforeq = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/edit/${UserId}`,payload) 
}

export const updateSitterDoc1req = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/edit/${UserId}`,payload) 
}

export const updateSitterDoc2req = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/edit/${UserId}`,payload) 
}

export const getUserDataByIdReq = (UserId) => {
  return axios.get(`${subDomain}administrator/sitter/profile/detail/${UserId}`)
}

export const getEditUserDataByIdReq = (UserId) => {
  return axios.get(`${subDomain}administrator/sitter/profile/detail/edit/${UserId}`)
}


// export const updateUserDataByIdReq = (payload, UserId) => {
//   return axios.post(`${subDomain}administrator/sitter/profile/detail/${UserId}`,payload)
// }

export const approveApplication1 = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/approve/application1/${UserId}`,payload)
}

export const approveApplication2 = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/approve/application2/${UserId}`,payload)
}

export const approveSitterReverification = (payload, UserId) => {
  return axios.put(`${subDomain}administrator/sitter/reverification/approve/${UserId}`,payload)
}

export const getSitterPartialPendingKycListreq = ({ search }) => {
  return axios.get(`${subDomain}administrator/sitter/partial-pending/list?search=${search}`) 
}

export const upadteUserActiveStatusDataByIdReq = (payload, UserId) => {
  return axios.post(`${subDomain}administrator/sitter/create/inactive/event/${UserId}`,payload)
}

export const clearUserActiveStatusDataByIdReq = (UserId) => {
  return axios.post(`${subDomain}administrator/sitter/clear/inactive/event/${UserId}`)
}

export const getCount = () => {
  return axios.get(`${subDomain}administrator/setting`) 
}