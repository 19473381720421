import AllFilters from '../Views/AllFilters'


export const FiltersRoutes = [
  {
    Component: AllFilters,
    path: '/all',
    exact: true,
    id: 'dashboardUsersFilters',
    routePath: '/dashboard/filter/all',
    name: 'Manual Sitter Search',
  },
]
