import React from 'react'
import Select from 'react-select'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  NativeSelect,
  Typography,
  FormControl,
  TextField
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import countryList from 'react-select-country-list'

const AddressModule = ({ data, incomingAddressData }) => {
  const [differentAddress, setdifferentAddress] = useState({
    sitterLocation: { location: '', checked: false },
    parentLocation: { location: '', checked: false },
    otherLocation: { location: '', checked: false },
  })
  const { sitterLocation, parentLocation, otherLocation } = differentAddress
  const [selectedAddressData, setSelectedAddressData] = useState({
    location_type: [], //parent_location, sitter_location, other_address,
    parent_address: '', //id of address
    manual_address: {
      street_name: null,
      city: null,
      country: null,
      postal_code: null,
    },
  })
  const countryOptions = useMemo(() => countryList().getData(), [])
  const [showAlertTxt, setShowAlertTxt] = useState(false)
  const [isDisabled, setIsDisabled] = useState({
    homeAddress: false,
    otherAddress: false,
  })

  useEffect(() => {
    data(selectedAddressData)
  }, [selectedAddressData])

  useEffect(() => {
    !sitterLocation.checked && !parentLocation.checked && !otherLocation.checked
      ? setShowAlertTxt(true)
      : setShowAlertTxt(false)
  }, [differentAddress])

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <Typography htmlFor="hear_from_source">
            At which location would you like your dog to be cared for?{' '}
            <span style={{ color: 'red', fontSize: '20px' }}>*</span>
          </Typography>
          <Box className="location-options">
            <FormControlLabel
              label="At dog sitter`s home"
              control={
                <Checkbox
                  checked={differentAddress.sitterLocation.checked}
                  onChange={() => {
                    setdifferentAddress({
                      ...differentAddress,
                      sitterLocation: {
                        location: 'sitter_location',
                        checked: !differentAddress.sitterLocation.checked,
                      },
                    })
                    selectedAddressData.location_type.includes(
                      'sitter_location'
                    )
                      ? setSelectedAddressData({
                        ...selectedAddressData,
                        location_type:
                          selectedAddressData.location_type.filter(
                            (value) => value != 'sitter_location'
                          ),
                      })
                      : setSelectedAddressData({
                        ...selectedAddressData,
                        location_type: [
                          ...selectedAddressData.location_type,
                          'sitter_location',
                        ],
                      })
                  }}
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
            <FormControlLabel
              label="At my home address"
              control={
                <Checkbox
                  checked={differentAddress.parentLocation.checked}
                  disabled={isDisabled.homeAddress}
                  onChange={() => {
                    setdifferentAddress({
                      ...differentAddress,
                      parentLocation: {
                        location: 'parent_location',
                        checked: !differentAddress.parentLocation.checked,
                      },
                    })
                    setIsDisabled({
                      ...isDisabled,
                      otherAddress: !differentAddress.parentLocation.checked,
                    })
                    selectedAddressData.location_type.includes(
                      'parent_location'
                    )
                      ? setSelectedAddressData({
                        ...selectedAddressData,
                        location_type:
                          selectedAddressData.location_type.filter(
                            (value) => value != 'parent_location'
                          ),
                      })
                      : setSelectedAddressData({
                        ...selectedAddressData,
                        location_type: [
                          ...selectedAddressData.location_type,
                          'parent_location',
                        ],
                      })
                  }}
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
            <FormControlLabel
              label="At a different address"
              control={
                <Checkbox
                  checked={differentAddress.otherLocation.checked}
                  disabled={isDisabled.otherAddress}
                  onChange={() => {
                    setdifferentAddress({
                      ...differentAddress,
                      otherLocation: {
                        location: 'other_address',
                        checked: !differentAddress.otherLocation.checked,
                      },
                    })
                    setIsDisabled({
                      ...isDisabled,
                      homeAddress: !differentAddress.otherLocation.checked,
                    })
                    selectedAddressData.location_type.includes('other_address')
                      ? setSelectedAddressData({
                        ...selectedAddressData,
                        location_type:
                          selectedAddressData.location_type.filter(
                            (value) => value != 'other_address'
                          ),
                      })
                      : setSelectedAddressData({
                        ...selectedAddressData,
                        location_type: [
                          ...selectedAddressData.location_type,
                          'other_address',
                        ],
                      })
                  }}
                  sx={{
                    color: '#fe7648',
                    '&.Mui-checked': { color: '#fe7648' },
                  }}
                />
              }
            />
          </Box>
          {showAlertTxt && (
            <p
              style={{
                fontFamily: 'Brandon',
                fontSize: '14px',
                color: 'red',
                margin: 0,
              }}
            >
              Please select the location type
            </p>
          )}
        </FormControl>
      </Grid>
      {differentAddress.parentLocation.checked &&
        differentAddress.parentLocation.location == 'parent_location' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <Typography shrink="true">Parent addresses</Typography>
            <NativeSelect
              disableUnderline
              defaultValue=""
              inputProps={{
                style: { fontFamily: 'Brandon', padding: '8.5px 14px' },
              }}
              onChange={(e) => {
                setSelectedAddressData({
                  ...selectedAddressData,
                  parent_address: e.target.value,
                })
              }}
              sx={{
                border: '1px solid #ced4d9',
                borderRadius: '3px',
                marginTop: '5px',
              }}
            >
              <option selected disabled value="">
                Select
              </option>
              {incomingAddressData && incomingAddressData.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.street_name +
                      ' ' +
                      item.city +
                      ' ' +
                      item.country +
                      ' ' +
                      item.postal_code}
                  </option>
                )
              })}
            </NativeSelect>
          </FormControl>
        </Grid>
      )}
      {differentAddress.otherLocation.checked &&
        differentAddress.otherLocation.location == 'other_address' && (
        <Grid container xs={12} sx={{ gap: '10px' }}>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                Street Name
              </Typography>
              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.street_name}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      street_name: e.target.value,
                    },
                  })
                }
                placeholder="Enter Street Name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                City
              </Typography>
              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.city}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      city: e.target.value,
                    },
                  })
                }
                placeholder="Enter City"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography shrink="true" htmlFor="date_of_birth">
                Postal Code
              </Typography>
              <TextField
                type="text"
                size="small"
                value={selectedAddressData.manual_address.postal_code}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      postal_code: e.target.value,
                    },
                  })
                }
                placeholder="Enter Postal Code"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormControl fullWidth variant="standard">
              <Typography
                shrink="true"
                htmlFor="date_of_birth"
                sx={{ marginBottom: '5px' }}
              >
                Country
              </Typography>
              <Select
                className="select-font"
                options={countryOptions}
                // value={selectedAddressData.manual_address.country}
                onChange={(e) =>
                  setSelectedAddressData({
                    ...selectedAddressData,
                    manual_address: {
                      ...selectedAddressData.manual_address,
                      country: e.label,
                    },
                  })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AddressModule
