import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { useEffect, useState } from 'react'

const EditOvernightData = ({ data, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')

  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: data.overnight_start_date_time,
    overnight_end_date_time: data.overnight_end_date_time,
    other_details: data.other_details
  })
  const { overnight_start_date_time, overnight_end_date_time, other_details } = overnightData

  useEffect(() => {
    const dateA = new Date(`${overnightData.overnight_start_date_time}`)
    const dateB = new Date(`${overnightData.overnight_end_date_time}`)
    const timeDifferenceInMilliseconds = dateB - dateA
    let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60) // In hours
    timeDifferenceInHours = timeDifferenceInHours >=24 ? timeDifferenceInHours : 24
    const timeDifferenceInDays = timeDifferenceInHours >= 24 ? (timeDifferenceInHours / 24) | 0 : 1  // In Days
    const timeDifferenceInModulas = timeDifferenceInHours >= 24 ? timeDifferenceInHours % 24 : 0 // In Modulas
    const dayDiff = timeDifferenceInModulas & (1 < timeDifferenceInModulas <= 5) ? `${timeDifferenceInDays + 0.5} Days` 
      : timeDifferenceInModulas > 5 ? `${timeDifferenceInDays + 1} Days` : timeDifferenceInDays
    const hoursDiff = timeDifferenceInHours < 24 ? `${timeDifferenceInHours} Hours` : ''
    const time = timeDifferenceInHours < 24 ? hoursDiff : dayDiff
    setOvernightTimeDiff(time)
    updatedData(overnightData)
  }, [overnightData])
  
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label>Start Date Time</Label>
            <Input
              type="datetime-local"
              className="form-control"
              onChange={(e) => {setOvernightData({ ...overnightData, overnight_start_date_time: e.target.value })}}
              value={overnight_start_date_time.slice(0, 16)}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>End Date Time</Label>
            <Input
              type="datetime-local"
              className="form-control"
              onChange={(e) => {setOvernightData({ ...overnightData, overnight_end_date_time: e.target.value })}}
              value={overnight_end_date_time.slice(0, 16)}
            />
          </FormGroup>
        </Col>
        <p className='font-bold text-end'>Total days: {overnightTimeDiff}</p>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Other Details</Label>
              <Input value={other_details} type="textarea" className="form-control"
                onChange={(e) => setOvernightData({ ...overnightData, other_details: e.target.value })} />
            </FormGroup>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default EditOvernightData