import { getBookingDetailsDataById, updateBookingInfoById } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import UpdateOvernightData from './EditType/overnight'
import ConfirmationBookingModal from './ConfirmModal'
import UpdateDaycareData from './EditType/daycare'

const UpdateBookingDetailsModal = ({ isOpen, parentUserId, bookingId, handleClose }) => {

  const [BookingData, setBookingData] = useState([])
  const [updatedDatesData, setUpdatedDatesData] = useState({})
  const [datesTimeDiff, setDatesTimeDiff] = useState(0)
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: ''
  })

  const handleBookingDetailsDataById = () => {
    console.log('ddddd')
  }

  useEffect(() => {
    getBookingDetailsDataById(bookingId).then((res) => {
      setBookingData(res.data.data)
    })
  },[])

  const dogNames = BookingData.dogs && BookingData.dogs.map((res) => `${res.name},`)

  const handleUpdateBooking = () => {
    {BookingData.service_type == 'Overnight Boarding' && updateBookingInfoById(updatedDatesData,
      BookingData?.id).then((res) => {
      if(res.data.response_code === 80){
        setConfirmationModal({ ...confirmationModal, isOpen: true })
        handleClose()
      }
    })}

    {BookingData.service_type == 'Daycare' && updateBookingInfoById( updatedDatesData,
      BookingData?.id).then((res) => {
      if(res.data.response_code === 80){
        setConfirmationModal({ ...confirmationModal, isOpen: true })
        handleClose()
      }
    })}
  }

  console.log(BookingData, 'BookingData')
  return(
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Update Booking Details</ModalHeader>
      <ModalBody>
        <Row className='mb-5'>
          <Col lg='3'>
            <p className='m-0'>Booking Id:</p>
            <p className='font-bold m-0'>{BookingData.booking_id}</p>
          </Col>
          <Col lg='3'>
            <p className='m-0'>Service Type:</p>
            <p className='font-bold m-0'>{BookingData.service_type}</p>
          </Col>
          <Col lg='3'>
            <p className='m-0'>Dog Parent Name:</p>
            <p className='font-bold m-0'>{BookingData.parent}</p>
          </Col>
          <Col lg='3'>
            <p className='m-0'>Dog Names:</p>
            <p className='font-bold m-0'>{dogNames}</p>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col lg='3'>
            <p className='m-0'>No. of dogs:</p>
            {BookingData.dogs && <p className='font-bold m-0'>{BookingData.dogs.length}</p>}
          </Col>
          <Col lg='3'>
            <p className='m-0'>Dog Sitter Name</p>
            <p className='font-bold m-0'>{BookingData.preferred_sitters_text}</p>
          </Col>
          <Col lg='3'>
            <p className='m-0'>No. of days</p>
            <p className='font-bold m-0'>{BookingData.total_day_count}</p>
          </Col>
        </Row>

        <Row className='mb-5'>
          <Col lg='12'>
            <p className='m-0'>Dates</p>
            <p className='font-bold m-0'>{BookingData.dates}</p>
          </Col>
        </Row>

        <Row>
          {BookingData.service_type == 'Overnight Boarding' &&
            <UpdateOvernightData updatedData={(e) => setUpdatedDatesData(e)} timeDiff={(e) => setDatesTimeDiff(e)}/>}

          {BookingData.service_type == 'Daycare' &&
            <UpdateDaycareData data={BookingData} updatedData={(e) => setUpdatedDatesData(e)} 
              handleBookingDetailsDataById={() => handleBookingDetailsDataById()} closeModal={() => handleClose()}/>}

        </Row>

        {BookingData.service_type == 'Overnight Boarding' && <Row className='mb-5'>
          <Col lg='3'>
            <p className='m-0'>No. of days:</p>
            {BookingData.dogs && <p className='font-bold m-0'>{datesTimeDiff || '-'}</p>}
          </Col>
          <Col lg='3'>
            <p className='m-0'>Change(In days):</p>
            {datesTimeDiff && <p className='font-bold m-0'>
              {datesTimeDiff - BookingData.total_day_count || '-'}</p>}
          </Col>
          <Col lg='3'>
            <p className='m-0'>Result:</p>
            {datesTimeDiff && <p className='font-bold m-0'>
              {(datesTimeDiff - BookingData.total_day_count) > -1 ? 'Additional Invoice' : 'Refund'}</p>}
          </Col>
        </Row>}

      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={() => setConfirmationModal({ ...confirmationModal, isOpen: true })}
          color="primary" className="waves-effect">Update</Button>
        <Button type="button" onClick={handleClose} color="light" className="waves-effect">Close</Button>
      </ModalFooter>
      <ConfirmationBookingModal
        bookingType={'Overnight Boarding'}
        isOpen={confirmationModal.isOpen}
        type={(datesTimeDiff - BookingData.total_day_count) > -1 ? 'Additional Invoice' : 'Refund'}
        handleConfirm={handleUpdateBooking}
        handleClose={() => setConfirmationModal({ ...confirmationModal, isOpen: !confirmationModal.isOpen })}
      />
    </Modal>
  )
}

export default UpdateBookingDetailsModal