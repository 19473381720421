import { useEffect, useState } from 'react'
import PaymentList from 'Components/PaymentList'
import { refundListTableHeading } from '../../tableCol.js'
import { getUsersRefundListData, initiateRefundData } from '../../Api.js'
import RefundList from 'Components/RefundList/index.jsx'
import RefundConfirmationDialog from 'Components/RefundConfirmationDialog/index.jsx'


const UsersRefundPaymentList = () => {
  const [usersList, setUsersList] = useState([])
  const [searchData, setSearchData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paymentId, setPaymentId] = useState('')
  const [refundDialog, setRefundDialog] = useState({ isOpen: false, type: '', approvedAmount: null, actualAmount: 0 })
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  })
  const { currentPage, totalSize } = paginationValue

  const handleGetRefundPaymentData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUsersRefundListData(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetRefundPaymentData({ page: 1, search: '' })
  }, [])



  const handleTablePageChangeTags = (type, { page, sizePerPage }) => {
    handleGetRefundPaymentData({ page, search: '' })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const handleSearch = async(e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleGetRefundPaymentData({ page:1 , search: val })
  }

  const hadnleRefundAction = () => {
    const obj = refundDialog?.type == 'approved' ? {
      'type_of_request': refundDialog?.type,
      'final_refund_amount': parseInt(refundDialog?.approvedAmount)
    } : {
      'type_of_request': refundDialog?.type,
    }
    initiateRefundData(obj, paymentId).then((res) => {
      setRefundDialog({ ...refundDialog, isOpen: false })
      res.data.response_code === 80 && handleGetRefundPaymentData({ page: 1, search: '' })
    })
  }

  const handleApporoveRefundReq = (paymentId, amount) => {
    setPaymentId(paymentId)
    setRefundDialog({ ...refundDialog, isOpen: true, type: 'approved', actualAmount: amount })
  }

  const handleRejectRefundReq = (paymentId) => {
    setPaymentId(paymentId)
    setRefundDialog({ ...refundDialog, isOpen: true, type: 'rejected' })
  }

  return(
    <>
      <RefundList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={usersList}
        tableHeading={refundListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        searchData={searchData}
        handleSearch={handleSearch}
        handleApporoveRefundReq={handleApporoveRefundReq}
        handleRejectRefundReq={handleRejectRefundReq}
      />

      {refundDialog.isOpen && <RefundConfirmationDialog
        isOpen={refundDialog.isOpen}
        type={refundDialog.type}
        handleClose={() => setRefundDialog({ ...refundDialog, isOpen: false, actualAmount: 0 })}
        handleChangeValue={(e) => setRefundDialog({ ...refundDialog, approvedAmount: e.target.value })}
        handleConfirm={hadnleRefundAction}
        actualAmount={refundDialog.actualAmount}
      />}

    </>
  )

}

export default UsersRefundPaymentList