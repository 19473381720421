import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import ViewBookingLocationComp from './viewLocationComp'

const ViewDaycareData = ({ daycareData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  return (
    <>
      <Row>
        <p className='m-0'>Daycare dates: <span className='font-bold'>{daycareData.dates}</span></p>
        <p className='m-0'>Total Days Count: <span className='font-bold'>{daycareData.total_day_count}</span></p>
        <p className='m-0'>Daycare dates are flexible?: 
          <span className='font-bold'> {daycareData.daycare_dates_flexible == true ? 'Yes' : 'No'}</span></p>
        <p className='m-0'>Additional Details: <span className='font-bold'>{daycareData.other_details}</span></p>
      </Row>
      <h5 className='my-2' style={{ color: '#e8833a', fontWeight: 500 }}>SERVICE LOCATION&apos;S </h5>
      {/* <h5 className='my-3'>Location`s selected: {daycareData.location_type}</h5> */}
      <ViewBookingLocationComp bookingData={daycareData} />
    </>
  )
}

export default ViewDaycareData