import axios from 'axios'
import { getLocal } from 'Utils/localStorage'

const subDomain = 'https://api.dogstays.lu/'

const token = getLocal('token')

const config = {
  headers: { Authorization: `Bearer ${token}` },
}

export const getUserListReqByFilters = ({
  page,
  start_date,
  end_date,
  Sensitivity,
  search_start_time,
  search_end_time,
  multiple_dates,
  offered_services,
  have_your_own_dog,
  have_your_own_cat,
  preferred_languages,
  daily_schedule,
  floor,
  home_has_elevator,
  have_children,
  size_of_dog,
  age_of_dog,
  comfortable_with_non_toilet_trained,
  comfortable_with_two_dogs,
  comfortable_with_separation_anxiety,
  outdoor_spaces,
  is_inactive,
  page_size,
  has_car,
  has_driving_license
}) => {
  return axios.get(
    `${subDomain}administrator/sitter/list?page=${page}&sensitivity=${Sensitivity}&` +
    `search_start_time=${search_start_time}&search_end_time=${search_end_time}&`  +
    `start_date=${start_date}&end_date=${end_date}&multiple_dates=${multiple_dates}` +
      `&offered_services=${offered_services}&have_your_own_dog=${have_your_own_dog}&have_your_own_cat=${have_your_own_cat}` +
      `&preferred_languages=${preferred_languages}&daily_schedule=${daily_schedule}&floor=${floor}` +
      `&home_has_elevator=${home_has_elevator}&have_children=${have_children}` +
      `&size_of_dog=${size_of_dog}&age_of_dog=${age_of_dog}` +
      `&comfortable_with_non_toilet_trained=${comfortable_with_non_toilet_trained}` +
      `&comfortable_with_two_dogs=${comfortable_with_two_dogs}&page_size=${page_size}` +
      `&comfortable_with_separation_anxiety=${comfortable_with_separation_anxiety}&has_car=${has_car}` +
      `&outdoor_spaces=${outdoor_spaces}&is_inactive=${is_inactive}&has_driving_license=${has_driving_license}`
  )
}

export const formatDate = (userDate) => {
  // format from M/D/YYYY to YYYYMMDD
  return new Date(userDate).toJSON().slice(0, 10).split('-').reverse().join('-')
}

export const getCalenderEventreq = (id) => {
  const today = new Date()
  const startDate = today.setMonth(today.getMonth() - 6)
  const endDate = today.setFullYear(today.getFullYear() + 2)
  return axios.get(
    `${subDomain}/administrator/sitter/events/${id}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  )
}

export const createCalenderEventreq = (data, id) => {
  return axios.post(`${subDomain}administrator/sitter/create/event/${id}`, data)
}

export const createDaycareBookingCalenderEventreq = (data, id) => {
  return axios.post(`${subDomain}administrator/sitter/create/daycare/event/${id}`, data)
}

export const deleteCalenderEventreq = (eventId, data) => {
  return axios.put(`${subDomain}administrator/event/cancel/${eventId}`, data)
}

export const editCalenderEventreq = (eventId, data) => {
  return axios.put(`${subDomain}administrator/event/edit/${eventId}`, data)
}

export const getCalenderEventByIdreq = (eventId) => {
  return axios.get(`${subDomain}administrator/event/detail/${eventId}`)
}

export const sendNotificationReq = (data) => {
  return axios.post(`${subDomain}basic/wix/send/booking/details`, data)
}