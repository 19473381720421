import { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import overlayFactory from 'react-bootstrap-table2-overlay'

const Table = ({
  rows,
  columns = [],
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  isLoading,
}) => {
  
  const [clickedRow, setSelectedRow] = useState({})

  const rowEvents = {
    // onMouseLeave: function(e, row, rowIndex) {
    onMouseLeave: function() {
      setSelectedRow(null)
    },
    onMouseEnter: function(e, row, rowIndex) {
      setSelectedRow(rowIndex)
    },
  }
  const rowStyle = (row, rowIndex) => {
    if (rowIndex === clickedRow) {
      return { background: '#f3f3f3' }
    }
  }
  return (
    <div className="table-responsive">
      <BootstrapTable
        remote
        striped
        hover
        condensed
        keyField="id"
        rowEvents={rowEvents}
        data={rows}
        columns={columns}
        rowStyle={rowStyle}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize,
          hideSizePerPage: true,
        })}
        bordered={true}
        onTableChange={onTableChange}
        loading={isLoading}
        overlay={
          rows?.length
            ? overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: 'rgba(0, 0, 0, 0.3)',
                }),
              },
            })
            : null
        }
      />
    </div>
  )
}

export default Table
