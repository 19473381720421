import { BookingRoutes } from '../Views/Bookings/Constants/routes'
import Booking from '../Views/Bookings/inde'
import MyConfig from '../Views/Config'
import { configRoutes } from '../Views/Config/routes'
import Filters from '../Views/Filters'
import { FiltersRoutes } from '../Views/Filters/Constants/routes'
import Meetings from '../Views/Meetings'
import { meetingsRoutes } from '../Views/Meetings/Constants/routes'
import Parent from '../Views/Parents'
import { parentsRoutes } from '../Views/Parents/Constants/routes'
import Payment from '../Views/Payments'
import { paymentRoutes } from '../Views/Payments/Constants/routes'
import Users from '../Views/Users'
import { UsersRoutes } from '../Views/Users/Constants/routes'

export const DashboardRoutes = [

  {
    Component: Users,
    path: '/users/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/users',
    name: 'Dog Sitters',
    icon: 'ri-user-settings-fill',
    subRoutes: UsersRoutes,
  },
  {
    Component: Filters,
    path: '/filter/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/filter',
    name: 'Search',
    icon: ' ri-filter-fill',
    subRoutes: FiltersRoutes,
  },
  {
    Component: Parent,
    path: '/parent/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/parent',
    name: 'Dog Parents',
    icon: ' ri-user-heart-fill',
    subRoutes: parentsRoutes,
  },
  {
    Component: Meetings,
    path: '/meeting/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/meeting',
    name: 'Meetings',
    icon: ' ri-user-heart-fill',
    subRoutes: meetingsRoutes,
  },
  {
    Component: Booking,
    path: '/booking/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/booking',
    name: 'Bookings',
    icon: ' ri-user-heart-fill',
    subRoutes: BookingRoutes,
  },
  {
    Component: Payment,
    path: '/payment/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/payment',
    name: 'Payments',
    icon: ' ri-user-heart-fill',
    subRoutes: paymentRoutes,
  },
  {
    Component: MyConfig,
    path: '/config/*',
    exact: false,
    id: 'dashboardUsers',
    routePath: '/dashboard/config',
    name: 'Configs',
    icon: ' ri-user-heart-fill',
    subRoutes: configRoutes,
  }
]
