import { assets } from 'assets/assests'
import { Fragment } from 'react'
import { Row, Col, Container } from 'reactstrap'

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()}© DogStays.</Col>
            {/* to do: change the text style */}
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block pb-4">
                <img
                  className="lms-logo"
                  width="100px"
                  height="30px"
                  src={assets.DogstayLogo}
                  alt="LMS"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  )
}

export default Footer
