export const ParentUsersListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Dog Parent ID',
    dataField: 'dog_parent_id',
  },
  {
    text: 'Date of joining',
    dataField: 'date_of_joining',
  },
  {
    text: 'Source',
    dataField: 'hear_from_source',
  },
  {
    text: 'First Name',
    dataField: 'first_name',
  },
  {
    text: 'Last Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Languages',
    dataField: 'preferred_languages',
  },
  {
    text: 'Dog Name(s)',
    dataField: 'dogs',
  },
]

export const BookingDetailsListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Received On',
    dataField: 'created_on',
  },
  {
    text: 'Parent Name',
    dataField: 'parent_full_name',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Dog`s Name',
    dataField: 'dogs',
  },
  {
    text: 'Service Type',
    dataField: 'service_type_display',
  },
  {
    text: 'Start Month',
    dataField: 'service_start_month',
    sort: true
  },
  {
    text: 'Location',
    dataField: 'location_type',
  },
  // {
  //   text: 'Dog Parent ID',
  //   dataField: 'parent_id',
  // },
  {
    text: 'Booking Details sent to',
    dataField: 'selected_sitters'
  },
  {
    text: 'Selected Sitter',
    dataField: 'final_sitter_details.name',
  },
  {
    text: 'Meeting',
    dataField: 'meeting_details',
  },
  {
    text: 'NEXT STEP',
    dataField: 'next_step',
  }
]

export const BookingSitterFilteredListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Age',
    dataField: 'age',
  },
  {
    text: 'Distance',
    dataField: 'distance',
    sort: true
  },
  {
    text: 'Offered Serices',
    dataField: 'offered_services',
  }
]

export const updateBookingRequestListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Received On',
    dataField: 'created_on',
  },
  {
    text: 'Parent Name',
    dataField: 'parent_full_name',
  },
  {
    text: 'Email',
    dataField: 'parent_email',
  },
  {
    text: 'Dog`s Name',
    dataField: 'dogs',
  },
  {
    text: 'Service Type',
    dataField: 'service_type',
  },
  {
    text: 'Location',
    dataField: 'location_type',
  },
  {
    text: 'Status',
    dataField: 'status_display_name',
  }
]