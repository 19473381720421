import { updateBookingAddOnsById } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const ApplyingBookingMOdal = ({ isOpen, handleClose, data, reRenderTable }) => {
  const [checkboxType, setCheckboxType] = useState({
    isAddOnChecked: false,
    isAdjustmentChecked: false,
    isDiscountChecked: false,
  })

  const [addOnServices, SetAddOnServices] = useState([])
  const [discount, SetDiscount] = useState([])
  const [adjustment, SetAdjustment] = useState([])

  const handleConfirm = () => {
    const mergeAllServices = [ ...addOnServices, ...discount, ...adjustment ]
    updateBookingAddOnsById( mergeAllServices ,data.id).then((res) => {
      res.data.response_code == 80 && handleClose(), reRenderTable()
    })
  }

  const addnewService = () => {
    const addOnServiceArr = [...addOnServices]
    const newObj = {
      name: '',
      value: '',
      type: 'addon'
    }
    addOnServiceArr.push(newObj)
    SetAddOnServices(addOnServiceArr)
  }

  const addDiscountService = () => {
    const discountServiceArr = [...discount]
    const newObj = {
      name: '',
      value: '',
      type: 'discount'
    }
    discountServiceArr.push(newObj)
    SetDiscount(discountServiceArr)
  }

  const addAdjustmentService = () => {
    const adjustmentServiceArr = [...adjustment]
    const newObj = {
      name: '',
      value: '',
      type: 'adjustment',
      adjustment_type:''
    }
    adjustmentServiceArr.push(newObj)
    SetAdjustment(adjustmentServiceArr)
  }

  useEffect(() => {
    const addOnServiceArr = []
    const discountArr = []
    const adjustmentArr = []
    data?.add_ons && data.add_ons.filter((value) => {
      value.type == 'addon' && addOnServiceArr.push(value)
      value.type == 'discount' && discountArr.push(value)
      value.type == 'adjustment' && adjustmentArr.push(value)
    })
    SetAddOnServices(addOnServiceArr)
    SetDiscount(discountArr)
    SetAdjustment(adjustmentArr)
    setCheckboxType(prevState => ({
      ...prevState,
      isAddOnChecked: prevState.isAddOnChecked || addOnServiceArr.length > 0,
      isDiscountChecked: prevState.isDiscountChecked || discountArr.length > 0,
      isAdjustmentChecked: prevState.isAdjustmentChecked || adjustmentArr.length > 0
    }))
  },[])

  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Apply To Booking</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={4}>
            <Label>Booking Req Id</Label><br/>
            <Label>{data?.booking_id}</Label>
          </Col>
          <Col xs={4}>
            <Label>Parent Name</Label><br/>
            <Label>{data?.parent_full_name}</Label>
          </Col>
          <Col xs={4}>
            <Label>Dog Name</Label><br/>
            <Label>{data?.dogs}</Label>
          </Col>
        </Row>
        <Row>
          <h4 className='text-center my-4'>APPLY TO BOOKING</h4>
          <Col xs={4} className='text-center'>
            <Input type='checkbox' checked={checkboxType.isAddOnChecked} onChange={() => {
              setCheckboxType({ ...checkboxType, isAddOnChecked: !checkboxType.isAddOnChecked })
            }}/> Add-On Services
          </Col>
          <Col xs={4} className='text-center'>
            <Input type='checkbox' checked={checkboxType.isAdjustmentChecked} onChange={() => {
              setCheckboxType({ ...checkboxType, isAdjustmentChecked: !checkboxType.isAdjustmentChecked })
            }}/> Adjustments
          </Col>
          <Col xs={4} className='text-center'>
            <Input type='checkbox' checked={checkboxType.isDiscountChecked} onChange={() => {
              setCheckboxType({ ...checkboxType, isDiscountChecked: !checkboxType.isDiscountChecked })
            }}/> Discount
          </Col>
        </Row>
        <Row className='justify-content-center'>

          {checkboxType.isAddOnChecked && <>
            <h4 className='text-center my-3'>ADD ON SERVICES</h4>
            <Col xs={12} className='text-end'><Button onClick={addnewService}>Add Service</Button></Col>
            {addOnServices && addOnServices.length > 0 && addOnServices.map((res, index) => {
              return(
                <Row className='justify-content-center align-items-center mt-5' key={index}>
                  <Col xs={4}>
                    <FormGroup>
                      <Label>Name of service</Label>
                      <Input type='text' value={res.name} 
                        onChange={(e) => {
                          const updatedData = { ...res, name: e.target.value }
                          SetAddOnServices((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>

                    <FormGroup>
                      <Label>Additional amount(in Euros)</Label>
                      <Input type='number' value={res.value}
                        onChange={(e) => {
                          const updatedData = { ...res, value: e.target.value }
                          SetAddOnServices((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>
                  </Col>
                  <Col xs={1}>
                    <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"
                      onClick={() => {
                        const addNewServiceCopy = [...addOnServices]
                        addNewServiceCopy.splice(index,1)
                        SetAddOnServices(addNewServiceCopy)
                      }}></i>
                  </Col>
                </Row>
              )
            })}
          </>
          }

          {checkboxType.isAdjustmentChecked && <>
            <h4 className='text-center my-3'>ADJUSTMENT</h4>
            <Col xs={12} className='text-end'><Button onClick={addAdjustmentService}>Add Adjustment</Button></Col>
            {adjustment && adjustment.length > 0 && adjustment.map((res, index) => {
              return(
                <Row className='justify-content-center align-items-center mt-5' key={index}>
                  <Col xs={4} >
                    <FormGroup>
                      <Label>Type</Label>
                      <Input
                        value={res.adjustment_type}
                        onChange={(e) => {
                          const updatedData = { ...res, adjustment_type: e.target.value }
                          SetAdjustment((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}
                        type="select"
                        className="form-control"
                      >
                        <option hidden>Select</option>
                        <option value='add'>Add to booking</option>
                        <option value='deduct'>Deduct from booking</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label>Reference</Label>
                      <Input type='text' value={res.name} 
                        onChange={(e) => {
                          const updatedData = { ...res, name: e.target.value }
                          SetAdjustment((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount(in Euros)</Label>
                      <Input type='number' value={res.value}
                        onChange={(e) => {
                          const updatedData = { ...res, value: e.target.value }
                          SetAdjustment((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>
                  </Col>
                  <Col xs={1}>
                    <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"
                      onClick={() => {
                        const adjustmenterviceCopy = [...adjustment]
                        adjustmenterviceCopy.splice(index,1)
                        SetAdjustment(adjustmenterviceCopy)
                      }}></i>
                  </Col>
                </Row>
              )
            })}
          </>}

          {checkboxType.isDiscountChecked && <>
            <h4 className='text-center my-3'>DISCOUNT</h4>
            <Col xs={12} className='text-end'><Button onClick={addDiscountService}>Add Discount</Button></Col>
            {discount && discount.length > 0 && discount.map((res, index) => {
              return(
                <Row className='justify-content-center align-items-center mt-5' key={index}>
                  <Col xs={4}>
                    <FormGroup>
                      <Label>Discount Reference</Label>
                      <Input type='text' value={res.name}
                        onChange={(e) => {
                          const updatedData = { ...res, name: e.target.value }
                          SetDiscount((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount(in Euros)</Label>
                      <Input type='number' value={res.value}
                        onChange={(e) => {
                          const updatedData = { ...res, value: e.target.value }
                          SetDiscount((prevData) => {
                            const newData = [...prevData]
                            newData[index] = updatedData
                            return newData
                          })
                        }}/>
                    </FormGroup>
                  </Col>
                  <Col xs={1}>
                    <i className="ri-delete-bin-3-fill text-danger h4 cursor-pointer me-2"
                      onClick={() => {
                        const discountServiceCopy = [...discount]
                        discountServiceCopy.splice(index,1)
                        SetDiscount(discountServiceCopy)
                      }}></i>
                  </Col>
                </Row>
              )
            })}
          </>}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleConfirm}
          color="primary"
          className="waves-effect"
        >
          Save
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ApplyingBookingMOdal