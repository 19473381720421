import { takeEvery, all, fork } from 'redux-saga/effects'

import {
  handleLogoutUserEffect,
} from './Auth.effects'

import { authActions } from './Auth.actions'

// function* watchLoginUser() {
//   yield takeEvery(authActions.LOGIN_USER, handleLoginUserEffect)
// }

function* watchLogoutUser() {
  yield takeEvery(authActions.LOGOUT_USER, handleLogoutUserEffect)
}

// function* watchFetchUserData() {
//   yield takeEvery(authActions.FETCH_USER_DATA, handleFetchUserDataEffect)
// }

export const authSagas = function* rootSaga() {
  yield all([ fork(watchLogoutUser), ])
}
