import { getMeetingLocationByBookingId, sharedToSitters, updateBookingStatusById } from 'Views/Dashboard/Views/Parents/Api'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const RefundConfirmationDialog = ({ isOpen, handleClose, type, handleChangeValue, handleConfirm, actualAmount }) => {

 
  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Action</ModalHeader>
      <ModalBody>
        <p>{`Refund Amount: €${actualAmount}`}</p>
        <h5>Are you sure!</h5>
        {type == 'approved' && 
          <FormGroup>
            <Label>Refund Amount</Label>
            <Input onChange={handleChangeValue} placeholder='Enter Amount' type="number" className="form-control"/>
          </FormGroup>}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleConfirm}
          color="primary"
          className="waves-effect"
        >
          Confirm
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RefundConfirmationDialog
