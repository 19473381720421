export const meetingListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Parent Name',
    dataField: 'parent',
  },
  {
    text: 'Sitter Name',
    dataField: 'sitter',
  },
  {
    text: 'Meeting Date',
    dataField: 'meeting_date',
  },
  {
    text: 'Meeting Time',
    dataField: 'meeting_time',
  },
  {
    text: 'Locations',
    dataField: 'location_type',
  },
  {
    text: 'Meeting Address',
    dataField: 'meeting_location',
  },

  {
    text: 'Service Type',
    dataField: 'service_type_display',
  },
  {
    text: 'Status',
    dataField: 'status',
  }
]

export const pendingMeetingListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Booking Req ID',
    dataField: 'booking_id',
  },
  {
    text: 'Parent Name',
    dataField: 'parent',
  },
  {
    text: 'Sitter Name',
    dataField: 'sitter',
  },
  {
    text: 'Meeting Date',
    dataField: 'meeting_date',
  },
  {
    text: 'Meeting Time',
    dataField: 'meeting_time',
  },
  {
    text: 'Locations',
    dataField: 'location_type',
  },
  {
    text: 'Meeting Address',
    dataField: 'meeting_location',
  },
  {
    text: 'Service Type',
    dataField: 'service_type_display',
  },
  {
    text: 'Status',
    dataField: 'status',
  }
]