import AllPendingUsers from '../Views/AllUsers'
import KycUsersList from '../Views/KycUsers'
import RegisteredUsersList from '../Views/RegisteredUsers'
import rejectedUsersList from '../Views/RejectedUser'
import PartialApprovedUsersList from '../Views/PartialApprovedUsers'
import { userRole } from 'Utils/helper'
import ReverificationSitterList from '../Views/ReverificationSitter'

export const UsersRoutes = ['super_admin', 'admin'].includes(userRole()) ? [
  {
    Component: AllPendingUsers,
    path: '/all',
    exact: true,
    id: 'dashboardUsersAll',
    routePath: '/dashboard/users/all',
    name: 'New Sitter Application',
  },
  {
    Component: PartialApprovedUsersList,
    path: '/partial',
    exact: true,
    id: 'dashboardUsersPartial',
    routePath: '/dashboard/users/partial',
    name: 'Partial Approved Users',
  },
  {
    Component: KycUsersList,
    path: '/kyc',
    exact: true,
    id: 'dashboardUsersKycAll',
    routePath: '/dashboard/users/kyc',
    name: 'Booking Preferences',
  },
  {
    Component: RegisteredUsersList,
    path: '/approved',
    exact: true,
    id: 'dashboardUsersApproved',
    routePath: '/dashboard/users/approved',
    name: 'Approved Sitters',
  },
  {
    Component: rejectedUsersList,
    path: '/rejected',
    exact: true,
    id: 'dashboardUsersRejected',
    routePath: '/dashboard/users/rejected',
    name: 'Rejected Sitters',
  },
  {
    Component: ReverificationSitterList,
    path: '/sitter-reverifaction',
    exact: true,
    id: 'dashboardSitterReverification',
    routePath: '/dashboard/users/sitter-reverifaction',
    name: `Reverification (${JSON.parse(localStorage.getItem('dataCount'))?.reverification_count || '0'})`,
  },
]: [
  {
    Component: RegisteredUsersList,
    path: '/approved',
    exact: true,
    id: 'dashboardUsersApproved',
    routePath: '/dashboard/users/approved',
    name: 'Approved Sitters',
  }
]
