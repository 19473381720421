import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const AllRefundList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleSearch,
  searchData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
}) => {
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={6}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">All Refunds</h3>
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
              <Col xs={4} className="d-flex align-items-center">
                <Label htmlFor="searchSitter">Search: </Label>
                <Input
                  style={{ height:'35px', marginLeft: '5px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AllRefundList
